import { useContext, useState, createContext, useMemo } from "react";
import {
  PhnConsentState,
  UserContextDefaults,
  UserRelated,
  UserRole,
  UserTypes,
} from "./UserTypes";

export const appCtxDefaultValue: UserContextDefaults = {
  userDetails: {
    name: "",
    role: UserRole.NULL,
    age: 0,
    related: [
      {
        name: "",
        role: UserRole.NULL,
        age: 0,
        agreementDate: 0,
        consent: false,
        assent: false,
        consentBy: [""],
        hasAgreedBefore: false,
        phn: null,
        identifier: "0",
        fhirIdsForProvisions: [""],
        fhirIdsForNotifications: [""],
      },
    ],
    agreementDate: 0,
    consent: false,
    assent: false,
    consentBy: [""],
    phn: "",
    identifier: "",
    hasAgreedBefore: false,
    email: "",
    userGroup: "",
    fhirIdsForProvisions: [""],
    fhirIdsForNotifications: [""],
  },
  setUserDetails: (userDetails: any) => {
    // This is intentional, using fn body to build type
  },
  userLoaded: false,
  setUserLoaded: (userLoaded: boolean) => {
    // This is intentional, using fn body to build type
  },
  children: [
    {
      name: "",
      role: UserRole.NULL,
      age: 0,
      agreementDate: 0,
      consent: false,
      assent: false,
      consentBy: [""],
      phn: "",
      identifier: "0",
      hasAgreedBefore: false,
      fhirIdsForProvisions: [""],
      fhirIdsForNotifications: [""],
    },
  ],
  setChildren: (children: Array<UserRelated> | []) => {
    // This is intentional, using fn body to build type
  },
  parent: undefined,
  setParent: (setParent: undefined | UserTypes | UserRelated) => {
    // This is intentional, using fn body to build type
  },
  mailRefer: false,
  setMailRefer: (mailRefer: boolean) => {
    // This is intentional, using fn body to build type
  },
  phnAgreement: {
    phn: "",
    performers: [""],
    phn_consent_state: PhnConsentState.NONE,
  },
  setPhnAgreement: (phnAgreement: any) => {
    // This is intentional, using fn body to build type
  },
  // fhirIdsForProvisions: [""],
  // setFhirIdsForProvisions: (fhirIds: Array<string>) => {
  //   // This is intentional, using fn body to build type
  // },
};

const UserContext = createContext(appCtxDefaultValue);

function useUser() {
  return useContext(UserContext);
}

interface UserProviderProps {
  children: React.ReactNode;
}

function UserProvider(props: UserProviderProps) {
  const [userDetails, setUserDetails] = useState(appCtxDefaultValue.userDetails);
  const [phnAgreement, setPhnAgreement] = useState(appCtxDefaultValue.phnAgreement);
  const [children, setChildren] = useState<Array<UserRelated> | []>([]);
  const [parent, setParent] = useState<undefined | UserTypes | UserRelated>(
    appCtxDefaultValue.parent
  );
  const [userLoaded, setUserLoaded] = useState(false);
  const [mailRefer, setMailRefer] = useState(false);
  // const [fhirIdsForProvisions, setFhirIdsForProvisions] = useState<Array<string> | []>([]);

  console.log(userDetails);
  // Memoized state management, used to reduce unnecessary state updates
  const providerValue = useMemo(
    () => ({
      userDetails,
      setUserDetails,
      userLoaded,
      setUserLoaded,
      children,
      setChildren,
      mailRefer,
      setMailRefer,
      parent,
      setParent,
      phnAgreement,
      setPhnAgreement,
      // fhirIdsForProvisions,
      // setFhirIdsForProvisions,
    }),
    [
      userDetails,
      setUserDetails,
      userLoaded,
      setUserLoaded,
      children,
      setChildren,
      parent,
      setParent,
      setMailRefer,
      mailRefer,
      phnAgreement,
      setPhnAgreement,
      // fhirIdsForProvisions, setFhirIdsForProvisions,
    ]
  );

  return <UserContext.Provider value={providerValue} {...props} />;
}
export { UserProvider, useUser };
