import LandingPage, {
  Caption,
  // ContactUs,
  // Footer,
  HomepageHeader,
  Section,
} from "../LandingPage.wrapper";

// import CTA from "../../../images/Landing_CTA.png";
import {
  useTranslation,
  // Trans
} from "react-i18next";
// import Icon, { IconEnum } from "../../../components/Icon";
import { skipHomeUserIsLoggedIn } from "../../routing/Landing/homePage.logic";
import { useHistory } from "react-router-dom";
import RedirectLogin from "../../../modals/RedirectLogin";
import { useEffect, useState } from "react";
import { CookieStorage } from "../../../components/services/Storage";
import Button from "../../../components/Button";
import browardLogo from "../../../images/logo.png";
import image from "../../../images/consent-img.png";

/*
Home Page (md4r-home)
- Landing page will check localStorage to see if an id exists, if it does will push to
  Redirect page on onClick button.
- Else if the localStorage key is not a valid id, send a  mock wallet api request onClick
  button then redirect to redirect/id?=<id of user> with the returned query param


- Use 'PageLanding' wrapper (reusable component) for styling

onClick Button -> 'I want to be a part of this!' -> redirect to modal -> that logs user 
*/

const HomePage = () => {
  const [t] = useTranslation("common");
  const history = useHistory();

  const [redirectLogin, setRedirectLogin] = useState(false);

  useEffect(() => {
    // does session cookie exist?
    if (CookieStorage.get("token")) {
      skipHomeUserIsLoggedIn(history);
    }
  }, [history]);

  return (
    <LandingPage pageName={HomePage.name}>
      <HomepageHeader
        title={t("title")}
        subtitle={t("subtitle")}
        // withLoginButton
        loginButtonOnClickHandler={() => setRedirectLogin(true)}
      />
      <div className="homePage__logo">
        <Section>
          <img src={browardLogo} className="homePage__img" alt="Broward County logo"></img>
        </Section>
      </div>
      <div className="homePage__body">
        <Section>
          <h2 className="homePage__title">{t("homePageTitle")}</h2>
          {/* <img src={CTA} alt="" className="homePage__CTA" /> */}
          <Caption className="homepage-caption">{t("homePageCaption")}</Caption>
          <Button
            aria-label="home-btn"
            onClick={() => setRedirectLogin(true)}
            className="btn homePage__btn btn--upper-margin"
          >
            {t("homePageBtn")}
          </Button>
        </Section>
        <Section>
          <img src={image} alt="" className="homePage__CTA" />
        </Section>
      </div>
      <RedirectLogin open={redirectLogin} setOpen={setRedirectLogin} />
    </LandingPage>
  );
};

export default HomePage;
