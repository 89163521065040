export const HOME = "/";
export const ABOUT = "/about";
export const ABOUTCONSENT = "/about#consentReview=consent";
export const ABOUTREVIEW = "/about#consentReview=review";
export const REDIRECT = "/redirect";
export const CHOOSECHILD = "/choose-child";
export const CHOOSE_PARENT = "/choose-parent";
export const FLOW_INTRO = "/consent-assent-intro";
export const CHILD_FLOW_INTRO = "/consent-assent-intro-child";
export const FLOW_EXPLAINED = "/consent-assent-explained";
export const FLOW_EXPLAINED_ADULT_AND_BABY = "/consent-assent-explained-adult";
export const NOTIFY_CARE_TEAM = "/notify-care-team";
export const SHARE_HEALTH_INFO = "/share-health-info";
export const LIABILITY_RELEASE = "/liability-release";
export const ONE_YEAR_TERM = "/one-year-term";
export const REVOKE = "/right-to-revoke";
export const DAMAGES_RELEASE = "/damages-release";
export const FLOW_NEEDED = "/consent-assent-needed";
export const FLOW_NEEDED_ADULT = "/consent-assent-needed-adult";
export const CHILD_ASSENT_PARENT_VIEW = "/child-assent";
export const CHILD_ASSENT_CHILD_VIEW = "/child-assent-cv";
export const PARENT_CONSENT_PARENT_VIEW = "/parent-consent";
export const PARENT_CONSENT_CHILD_VIEW = "/parent-consent-cv";
export const ADULT_CONSENT = "/adult-consent";
export const PHN_PARENT = "/phn-parent-info";
export const PHN_ADULT = "/phn-adult-info";
export const PHN_CHILD = "/phn-child-info";
export const PHN_PARENT_VIEW = "/phn";
export const PHN_CHILD_VIEW = "/phn-cv";
export const DASHBOARD = "/dashboard";
export const PARENT_LEARN = "/parent-learn";
export const CHILD_LEARN = "/child-learn";
export const PARENT_STOP = "/parent-stop";
export const PDF = "/pdf";
export const REVIEW_AGREEMENTS_ASSENT = "/review-agreements-assent";
export const REVIEW_NOTIFY_CARE_TEAM = "/review-notify-care-team";
export const REVIEW_SHARE_HEALTH_INFO = "/review-share-health-info";
export const REVIEW_AGREEMENTS_CONSENT = "/review-agreements-consent";
export const REVIEW_AGREEMENTS_ADULT_CONSENT = "/review-agreements-consent-adult";
export const REVIEW_AGREEMENTS_PHN = "/review-agreements-phn";
export const REVIEW_AGREEMENTS_PHN_PARENT_INFO = "/review-agreements-phn-parent-info";
export const REVIEW_AGREEMENTS_PHN_CHILD_INFO = "/review-agreements-phn-child-info";
export const REVIEW_AGREEMENTS_PHN_ADULT_INFO = "/review-agreements-phn-adult-info";
export const CHOOSE_USER = "/choose-user";
export const RESET_USER = "/reset";

export const ERROR_EXPIRED_SESSION = "/error-expired-session";
export const ERROR_NO_SESSION = "/error-no-session";
export const ERROR_NO_PROFILE = "/error-no-profile";
export const ERROR_CONSENT_NOT_NEEDED = "/error-consent-not-needed";
export const LOGIN = "/login";



export const INITIALS_PAGE = "/initials"
export const CURRENT_CARE_TEAM = "/current-care-team"
export const CARE_TEAM_ORGANIZATIONS = "/care-team-organizations"
