import { useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useUser } from "../../context/UserContext/UserContext";
import { UserRelated, UserRole, UserTypes } from "../../context/UserContext/UserTypes";
import { useUtil } from "../../context/UtilContext/UtilContext";
import ChildStop from "../../modals/ChildStop";
import HomeDataCollection from "../../modals/HomeDataCollection";
import ParentStop from "../../modals/ParentStop";
import {
  AgreementSection,
  StopParticipatingButton,
} from "../../navigation/pages/LandingPage.wrapper";
import { goToStopParticipatingChildren } from "../../navigation/routing/redirectPage.logic";
// import { goToAboutPageReview } from "../../navigation/routing/Flow/about.logic";
// import { isAdultPatient, isBaby } from "../../utils/handleChildAge";
import {
  handleChildrenParticipation,
  handleMainUserChildParticipation,
} from "../../utils/handleParticipation";
import { useRoute } from "../../context/RouteContext/RouteContext";
import useRouteControl, { routeScenarioEnum } from "../../hooks/RouteController/useRouteController";
import plus from "../../images/plusblue.png";
import { CaptionContainer } from "../../navigation/pages/FlowPage.wrapper";

interface FullAgreementProps {
  children?: Array<UserRelated>;
  parent?: UserTypes | UserRelated;
  adultUser?: UserTypes;
}

const FullAgreement = ({
  children: childrenWithAgreement,
  parent,
  adultUser,
}: FullAgreementProps) => {
  const [parentStopModal, setParentStopModal] = useState(false);
  const [childStopModal, setChildStopModal] = useState(false);
  const [dataCollectionModal, setDataCollectionModal] = useState(false);

  const [t] = useTranslation("common");
  const { fullAgreement } = useUtil();
  const { children, setChildren, userDetails, setUserDetails } = useUser();
  const history = useHistory();
  const { routeScenario } = useRoute();

  const handleError = useErrorHandler();

  if (
    typeof adultUser === "undefined" &&
    typeof parent === "undefined" &&
    typeof childrenWithAgreement === "undefined"
  ) {
    // throw error if all props are undefined
    throw handleError({ message: "All props must be defined in Full Agreement component" });
  }

  // const onClickModalHandler = () => {
  //   setDataCollectionModal(true);
  // };

  const stopParticipatingHandler = () => {
    if (userDetails.role === UserRole.PARENT) {
      ParentParticipatingHandler();
    } else if (adultUser) {
      setChildStopModal(true);
    } else if (userDetails.role === UserRole.PATIENT) {
      ChildParticipatingHandler();
    }
  };

  const ChildParticipatingHandler = () => {
    // Check how many fully agreed children, use:
    // if 1 return modal
    if (fullAgreement.length === 1) {
      console.log("modal");
      setChildStopModal(true);
    }
  };

  const ParentParticipatingHandler = () => {
    // Check how many fully agreed children, use:
    // if 1 return modal
    if (fullAgreement.length === 1) {
      console.log("modal");
      setParentStopModal(true);
    }
    // if greater than 1 return parent-stop screen
    else if (fullAgreement.length >= 1) {
      console.log("parent-stop screen");
      goToStopParticipatingChildren(history);
    }
  };

  const handleParentModalOnContinue = async () => {
    const { children: newChildren } = await handleChildrenParticipation(
      fullAgreement,
      userDetails,
      children,
      userDetails.identifier
    );
    setChildren(newChildren);
  };
  const handleChildModalOnContinue = () => {
    const { user } = handleMainUserChildParticipation(userDetails, userDetails.identifier);
    setUserDetails(user);
  };

  const [handleRoute] = useRouteControl(history);

  const onClickHandler = () => {
    handleRoute(routeScenario as routeScenarioEnum);
  };

  // const reviewAgreementsButtonHandler = () => {
  //   // on userDetails check is AdultPatient
  //   let userGroup: "adult" | "child" | "baby";

  //   if (isAdultPatient(userDetails.age, userDetails.role)) {
  //     userGroup = "adult";
  //   } else if (isBaby(children[0].age)) {
  //     userGroup = "baby";
  //   } else {
  //     userGroup = "child";
  //   }
  //   setUserDetails({ ...userDetails, userGroup });
  //   goToAboutPageReview(history);
  // };

  return (
    <>
      <AgreementSection>
        <h3>Active Consent Forms</h3>
        {/* <Icon iconName={IconEnum.hands} className="homeAuth__icon" withIconClass={false} /> */}
        {/* <p className="homeAuth__p homeAuth__p--left">{t("homeFullAgreementTitle")}</p> */}

        {/* {childrenWithAgreement &&
          childrenWithAgreement.map(
            (child) => isChild(child.age) && <AgreementRecord key={child.identifier} user={child} />
          )}
        {parent && <AgreementRecord key={parent.identifier} user={parent} isParent={true} />}
        {adultUser && (
          <AgreementRecord key={adultUser.identifier} user={adultUser} isAdultPatient={true} />
        )} */}

        {userDetails.role === UserRole.PARENT ? (
          <p className="homeAuth__p homeAuth__p--left homeAuth__p--nospacing">
            {t("homeFullAgreementDesc1Parent")}
          </p>
        ) : (
          <p className="homeAuth__p homeAuth__p--left homeAuth__p--nospacing">
            {t("homeFullAgreementDesc1")}
          </p>
        )}

        {/* <Button
          onClick={onClickModalHandler}
          className="landingPage__link landingPage__link--small landingPage__link--flex"
        >
          {t("homeFullAgreementDesc2")}
        </Button> */}
        <div className="landingPage__consent consent-given">
          <div className="landingPage__consentForm">
            {/* <ConsentFormButton status="fullAgreement" onClickHandler={onClickHandler} /> */}
            <li className={"landingPage__consentItem"}>
              <button onClick={onClickHandler}>
                <img src={plus} alt="" />
                <p>EDIT</p>
              </button>
              <div className="landingPage__p">
                <h4>Baker Act Parental Consent</h4>
                <p>
                  Parent Consent to Notify CARE Team Members and Share CARE Team Member Information
                </p>
              </div>
            </li>
          </div>
        </div>
        <StopParticipatingButton onClickHandler={stopParticipatingHandler} />

        <h3 className="second-title">Pending Consent Forms</h3>
        <p className="homeAuth__p">{t("homeMissingAgreementDesc1")}</p>
        <div className="landingPage__consent">
          {/* <div className="landingPage__consentForm"> */}
          {/* <ConsentFormButton status="fullAgreement" onClickHandler={onClickHandler} /> */}
          <CaptionContainer>
            <p className="landingPage__caption">{t("noPendingConsent")}</p>
          </CaptionContainer>
          {/* </div> */}
        </div>
        <h3 className="second-title">Past Consent Forms</h3>
        <p className="homeAuth__p">{t("homeMissingAgreementDesc1")}</p>
        <div className="landingPage__consent">
          {/* <div className="landingPage__consentForm"> */}
          {/* <ConsentFormButton status="fullAgreement" onClickHandler={onClickHandler} /> */}
          <CaptionContainer>
            <p className="landingPage__caption">{t("noPastConsent")}</p>
          </CaptionContainer>
          {/* </div> */}
        </div>
        {/* <Button onClick={onClickHandler} className="btn btn--secondary btn--upper-margin">
          {t("reviewAgreement")}
        </Button> */}
        {/* <Button
          onClick={reviewAgreementsButtonHandler}
          className="btn btn--secondary btn--upper-margin"
        >
          {t("reviewAgreement")}
        </Button> */}
      </AgreementSection>
      {/* <StopParticipatingButton onClickHandler={stopParticipatingHandler} /> */}
      {childrenWithAgreement && (
        <ParentStop
          open={parentStopModal}
          setOpen={setParentStopModal}
          onClickHandler={handleParentModalOnContinue}
          childName={fullAgreement[0].name}
        />
      )}
      {childrenWithAgreement && (
        <ChildStop
          open={childStopModal}
          setOpen={setChildStopModal}
          onClickHandler={handleChildModalOnContinue}
          childName={fullAgreement[0].name}
        />
      )}
      {adultUser && (
        <ChildStop
          open={childStopModal}
          setOpen={setChildStopModal}
          onClickHandler={handleChildModalOnContinue}
          childName={adultUser.name}
        />
      )}
      <HomeDataCollection open={dataCollectionModal} setOpen={setDataCollectionModal} />
    </>
  );
};

export default FullAgreement;
