import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useUser } from "../../../context/UserContext/UserContext";
import Button from "../../../components/Button";
import { IconEnum } from "../../../components/Icon";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import { getCareTeam } from "../../../API/Endpoints";
import useRouteControl, {
  routeScenarioEnum,
} from "../../../hooks/RouteController/useRouteController";
import FlowPage, {
  Header,
  Body,
  Title,
  Desc,
  BtnContainer,
  ProgressBar,
  PractitionerNotifyList,
  Practitioner,
  // CaptionContainer,
} from "../FlowPage.wrapper";
import plus from "../../../images/plus.png";

// interface Practitioner {
//   id: string; // Adjust the type if necessary
//   name: string;
//   organization: string;
//   role: string;
//   // Add other properties of the practitioner if necessary
// }

const NotifyCareTeam = () => {
  const { t } = useTranslation();
  const { routeScenario } = useRoute();
  const history = useHistory();
  const { parent } = useUser();

  const [handleRoute] = useRouteControl(history);

  const handleNext = () => {
    handleRoute(routeScenario as routeScenarioEnum);
  };

  const goBack = () => {
    history.goBack();
  };

  const [practitionersToNotify, setPractitionersToNotify] = useState<any[]>([]);

  const addToPractitionersToNotify = (member: any) => {
    setPractitionersToNotify((prevMembers) => {
      const isMemberInList = prevMembers.some((prevMember) => prevMember.name === member.name);

      if (isMemberInList) {
        // Remove the member from the list
        const updatedMembers = prevMembers.filter((prevMember) => prevMember.name !== member.name);
        updateUserFhirIdsForNotifications(updatedMembers);
        return updatedMembers;
      } else {
        // Add the member to the list
        const updatedMembers = [...prevMembers, member];
        updateUserFhirIdsForNotifications(updatedMembers);
        return updatedMembers;
      }
    });
  };

  const updateUserFhirIdsForNotifications = (updatedMembers: any[]) => {
    // console.log(updatedMembers);
    if (parent) {
      parent.fhirIdsForNotifications = updatedMembers.map((member) => member.fhir_id);

      // Update the parent object in the user context
      // with the modified fhirIdsForNotifications array
      // This will depend on how your user context is implemented.
      // You might need to call a setUser or updateParent function
      // provided by the UserContext.
      console.log(parent.fhirIdsForNotifications);
    }
  };

  console.log("practitionersToNotify: ", practitionersToNotify);

  // const prac = useUser().parent?.fhirIdsForNotifications;
  // console.log("stored in fhir ID: ", prac);

  async function getTeamData() {
    const data = await getCareTeam();
    console.log(data.data.participants);
    const practitionerTeam = data.data.participants.filter(
      (doc: { fhir_id: string }) => !doc.fhir_id.includes("Patient")
    );

    console.log(practitionerTeam);
    return practitionerTeam;
  }

  useEffect(() => {
    const fetchCareTeam = async () => {
      const teamData = await getTeamData();
      setCareTeam(teamData);
    };

    fetchCareTeam();
  }, []);

  const [careTeam, setCareTeam] = useState([]);

  useEffect(() => {
    // Get the initial list of practitioners to notify based on fhirIdsForNotifications
    const initialPractitionersToNotify = careTeam.filter((person: Practitioner) =>
      parent?.fhirIdsForNotifications.includes(person.fhir_id)
    );
    setPractitionersToNotify(initialPractitionersToNotify);
  }, [careTeam, parent?.fhirIdsForNotifications]);

  // const careTeam = getTeamData();
  return (
    <FlowPage pageName={NotifyCareTeam.name}>
      <Header />
      <ProgressBar level={1} description={t("progressBar1")} icon={IconEnum.beaker} />
      <div className="flowPage__padding">
        <Body>
          <Title>{t("caNotifyTitle")}</Title>
          <Desc>
            <p className="flowPage__p">{t("caNotifyDesc1")}</p>
            <p className="flowPage__p">
              <Trans>{t("caNotifyDesc2")}</Trans>
            </p>
            <p className="flowPage__p">{t("caNotifyDesc3")}</p>
          </Desc>
          {/* <CaptionContainer>
          <p className="flowPage__caption">{t("caNotifyCaption")}</p>
        </CaptionContainer> */}
          <div className="flowPage__careTeamTitle">
            <h3>Care team members: </h3>
            <button>Notify All</button>
          </div>
          <div className="flowPage__careTeam">
            {careTeam.map((person) => (
              <div className="flowPage__careTeamPractitioner">
                <PractitionerNotifyList
                  person={person}
                  addToPractitionersToNotify={addToPractitionersToNotify}
                  parent={parent}
                />
              </div>
            ))}
          </div>
          <BtnContainer reverse>
            <Button className="btn--outline">
              <img src={plus} alt="" />
              <p>Add a Care Team Member</p>
            </Button>
            <Button className="btn--outline">Do Not Notify</Button>
            <div className="button-navigation">
              <Button className="btn btn--primary" onClick={goBack}>
                {t("back")}
              </Button>
              <Button className="btn btn--primary" onClick={handleNext}>
                {t("next")}
              </Button>
            </div>
          </BtnContainer>
        </Body>
      </div>
    </FlowPage>
  );
};

export default NotifyCareTeam;
