import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import { HOME, PHN_CHILD, REDIRECT, DASHBOARD } from "../../utils/RouteConstants";

export const parentConsentController = (
  types: RouteTypes,
  history: RouteComponentProps["history"]
) => {
  if (types.d_child) history.push(PHN_CHILD);
  else if (types.i_child) history.push(PHN_CHILD);
  else if (types.d_baby) history.push(DASHBOARD);
  else if (types.d_missingConsent) history.push(DASHBOARD);
  else if (types.d_mailChild) history.push(DASHBOARD);
  else if (types.i_mailParent) history.push(REDIRECT);
  else history.push(HOME);
};
