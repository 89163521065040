import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import { CARE_TEAM_ORGANIZATIONS, FLOW_NEEDED_ADULT } from "../../utils/RouteConstants";

export const shareHealthInfoController = (
  types: RouteTypes,
  history: RouteComponentProps["history"]
) => {
  if (types.i_adult) history.push(FLOW_NEEDED_ADULT);
  else if (types.d_baby) history.push(CARE_TEAM_ORGANIZATIONS);
};
