import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import {
  CHILD_ASSENT_PARENT_VIEW,
  CHILD_ASSENT_CHILD_VIEW,
  HOME,
  PARENT_CONSENT_PARENT_VIEW,
  ONE_YEAR_TERM,
} from "../../utils/RouteConstants";

export const caDamagesController = (types: RouteTypes, history: RouteComponentProps["history"]) => {
  if (types.d_child) history.push(CHILD_ASSENT_PARENT_VIEW);
  else if (types.d_baby) history.push(ONE_YEAR_TERM);
  else if (types.i_child) history.push(CHILD_ASSENT_CHILD_VIEW);
  else if (types.d_missingConsent) history.push(PARENT_CONSENT_PARENT_VIEW);
  else if (types.i_missingAssent) history.push(CHILD_ASSENT_CHILD_VIEW);
  else if (types.d_mailChild) history.push(PARENT_CONSENT_PARENT_VIEW);
  else if (types.i_mailParent) history.push(CHILD_ASSENT_CHILD_VIEW);
  else history.push(HOME);
};
