import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import TrackAnalytics from "../../components/Analytics/TrackAnalytics";
import Icon, { IconEnum } from "../../components/Icon";
import Mailto from "../../components/Mailto";
import Phoneto from "../../components/Phoneto";
import SpinnerImage from "../../components/SpinnerImage";
import HeaderImageByPath from "../../services/HeaderImageByPath";
import check from "../../images/check-solid.png";
import ximage from "../../images/icon_close.png";
import { HomepageHeader } from "./LandingPage.wrapper";

/*
- A page that is used to wrap the consent-flow pages.
- Should have header sub component, with progress Bar
- Reusable wrapper to create pages with these attributes:

* PageFlow (wrapper)
* Header(progressBarProps, image). multiple images 
* ChooseChildContainer(Handling multiple children)
* Title(title)
* Body(container for title & desc)
* Desc(description container for blocks of text)
* CaptionContainer (Blue box, with italic text). with ::before&after selector
    for quotes ("")
* AlertContainer (grey box, with icon)
* BtnContainer (Btn Container to handle media queries for btns)
+ ModalContainer(Modals)
*/

interface Props {
  children?: React.ReactNode;
  textLeft?: boolean;
  textCenter?: boolean;
}

interface FlowPageProps extends Props {
  withOneButtonLayout?: boolean;
  loading?: boolean;
  anchorButtonGroup?: boolean;
  pageName: string;
}

let GLOBAL_anchorButtonGroup: boolean | undefined;
const FlowPage = ({
  children,
  textLeft = false,
  textCenter = false,
  withOneButtonLayout = false,
  loading = false,
  anchorButtonGroup = true,
  pageName,
}: FlowPageProps) => {
  const [t] = useTranslation("common");

  const handleButtonLayout = withOneButtonLayout ? "flowPage--oneButton" : "";
  const handleLoaderLayout = loading ? "loader" : "";

  GLOBAL_anchorButtonGroup = anchorButtonGroup;

  // Conditional length button-grouping anchoring for multiple views
  const handleAnchorButtonGroup = anchorButtonGroup ? "" : "flowPage--button-not-anchored";
  const handleFlowContainerNoSizeRestriction = anchorButtonGroup
    ? ""
    : "flow-container--no-size-restriction";

  window.scrollTo({ top: 0 });

  const globalPageName = { current: "" };
  globalPageName.current = pageName;

  useEffect(() => {
    TrackAnalytics("on_load", "user has loadded page component", pageName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={`flow-container ${handleFlowContainerNoSizeRestriction}`}>
        <HomepageHeader title={t("title")} subtitle={t("subtitle")} withLogoutButton={true} />
        <div className="flow-app">
          <div
            className={` flowPage ${handleButtonLayout} ${handleAnchorButtonGroup} scrollbox ${
              textLeft ? "text--left" : ""
            }
          ${textCenter ? "text--center" : ""}
          `}
          >
            {children}
          </div>
        </div>
      </div>
      {loading && (
        <div className={handleLoaderLayout}>
          <SpinnerImage loading={loading} />
        </div>
      )}
    </>
  );
};

interface HeaderProps extends Props {
  pathOverride?: string;
}

/**
 * Header component used in many Flow Pages.
 * This component reads the current pathname of the app and displays a relevant header image.
 * @param pathOverride is an escape hatch to input any pathname if needed. Enter any pathname from the RouteConstants file.
 * @returns header image
 */
export const Header = ({ children, pathOverride }: HeaderProps) => {
  let w820 = HeaderImageByPath(window.location.pathname);

  if (pathOverride) {
    w820 = HeaderImageByPath(pathOverride);
  }
  return (
    <div data-testid="FlowPage-Header" className={` flowPage__header`}>
      <img src={w820} alt="header" className="flowPage__headerImg" />
      {children}
    </div>
  );
};

interface ProgressBarPropss {
  level?: number;
  description?: string;
  icon: IconEnum;
}

export const ProgressBar = ({ level = 0, description = "", icon }: ProgressBarPropss) => {
  const [isProgressBarON, setIsProgressBarON] = useState(false);

  useEffect(() => {
    level ? setIsProgressBarON(true) : setIsProgressBarON(false);
  }, [level]);

  const progressBarInner = () => {
    return (
      <div className="progress">
        <Icon iconName={icon} withIconClass={false} className="progress__icon" />
        <div className="progress__container" style={{ display: level ? "inherit" : "none" }}>
          <p className="progress__desc">{description}</p>
          <div className="progress__bar">
            <div className="progress__bar--inner" style={{ width: level * 12.5 + "%" }}></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="progressBar__container">{isProgressBarON && progressBarInner()}</div>
    </>
  );
};

export const ChooseChildContainer = ({ children }: Props) => (
  <div data-testid="FlowPage-ChooseChildContainer" className={`flowPage__ChooseChildContainer`}>
    {children}
  </div>
);

interface TitleProps extends Props {
  children: string;
}

// + Title(title)
export const Title = ({ children, textLeft = true }: TitleProps) => {
  const handleTextAlignment = textLeft ? "text--left" : "text--center";

  return (
    <h1 data-testid="FlowPage-Title" className={`flowPage__title bold ${handleTextAlignment}`}>
      {children}
    </h1>
  );
};

// + Body(container for title & desc)
export const Body = ({ children }: Props) => (
  <div data-testid="FlowPage-Body" className={` flowPage__body `}>
    {children}
  </div>
);

// + Desc(description container for blocks of text)
export const Desc = ({ children }: Props) => {
  const handleDescNoSizeRestriction = GLOBAL_anchorButtonGroup
    ? ""
    : "flowPage__desc--no-size-restriction";

  return (
    <div
      data-testid="FlowPage-Desc"
      className={` flowPage__desc ${handleDescNoSizeRestriction} scrollbox`}
    >
      {children}
    </div>
  );
};
// Checklist & Checklist Item
export const CheckList = ({ children }: Props) => (
  <ul data-testid="FlowPage-CheckList" className={`flowPage__list`}>
    {children}
  </ul>
);

interface CheckListItemProps {
  children: string;
}

export const CheckListItem = ({ children }: CheckListItemProps) => (
  <li className="flowPage__listItem">
    <Icon iconName={IconEnum.check} />
    <p className="flowPage__p">
      <Trans>{children}</Trans>
    </p>
  </li>
);

export interface Practitioner {
  name: string;
  organization: string;
  role: string;
  fhir_id: string;
}

export const PractitionerNotifyList = ({
  person,
  addToPractitionersToNotify,
  parent,
}: {
  person: Practitioner;
  addToPractitionersToNotify: (person: Practitioner) => void;
  parent: any;
}) => {
  const [notified, setNotified] = useState(false);

  useEffect(() => {
    // Check if the person is in the fhirIdsForNotifications array
    const isPersonnotified = parent?.fhirIdsForNotifications.includes(person.fhir_id);
    setNotified(isPersonnotified);
  }, [person.fhir_id, parent?.fhirIdsForNotifications]);

  const handleClick = () => {
    setNotified((prevState) => !prevState);
    addToPractitionersToNotify(person);
  };

  return (
    <li className={`flowPage__listItem ${notified ? "unnotified" : "notified"}`}>
      <button onClick={handleClick}>
        <img src={notified ? check : ximage} alt="" />
        <p>{`${notified ? "Notify" : "Do Not Notify"}`}</p>
      </button>
      <div className="flowPage__p">
        <h4>{person.name}</h4>
        <p>{`${person.role}${person.organization ? `, ${person.organization}` : ""}`}</p>
      </div>
    </li>
  );
};

export const PractitionerShareList = ({
  person,
  addToPractitionersToShare,
  parent,
}: {
  person: Practitioner;
  addToPractitionersToShare: (person: Practitioner) => void;
  parent: any;
}) => {
  const [notified, setNotified] = useState(false);

  useEffect(() => {
    // Check if the person is in the fhirIdsForProvisions array
    const isPersonnotified = parent?.fhirIdsForProvisions.includes(person.fhir_id);
    setNotified(isPersonnotified);
  }, [person.fhir_id, parent?.fhirIdsForProvisions]);

  const handleClick = () => {
    setNotified((prevState) => !prevState);
    addToPractitionersToShare(person);
  };

  return (
    <li className={`flowPage__listItem ${notified ? "unnotified" : "notified"}`}>
      <button onClick={handleClick}>
        <img src={notified ? check : ximage} alt="" />
        <p>{`${notified ? "Share" : "Do Not Share"}`}</p>
      </button>
      <div className="flowPage__p">
        <h4>{person.name}</h4>
        <p>{`${person.role}${person.organization ? `, ${person.organization}` : ""}`}</p>
      </div>
    </li>
  );
};

// + CaptionContainer (Blue box, with italic text). with ::before&after selector
//     for quotes ("")
export const CaptionContainer = ({ children }: Props) => (
  <div data-testid="FlowPage-CaptionContainer" className={` flowPage__captionContainer`}>
    {children}
  </div>
);

// + AlertContainer (grey box, with icon)
export const AlertContainer = ({ children }: Props) => (
  <div data-testid="FlowPage-AlertContainer" className={` flowPage__alertContainer`}>
    <Icon iconName={IconEnum.bell} />
    <p className="flowPage__alert">{children}</p>
  </div>
);

// + Accept AlertContainer (green outline box with checkmark icon)
export const AcceptAlertContainer = ({ children }: Props) => (
  <div data-testid="FlowPage-AcceptAlertContainer" className={` flowPage__acceptAlertContainer`}>
    <Icon iconName={IconEnum.check} />
    <strong>{children}</strong>
  </div>
);

interface ContactUsProps {
  textLeft?: boolean;
}

// + ContactUs Container
export const ContactUs = ({ textLeft = false }: ContactUsProps) => {
  const [t] = useTranslation("common");

  return (
    <div data-testid="FlowPage-ContactUs" className={` flowPage__contactUs`}>
      <p className={`flowPage__contactUs ${textLeft ? "flowPage__contactUs--left" : ""}`}>
        <Trans
          components={{
            phone: <Phoneto phoneNumber={t("researchTel")} className="landingPage__link" />,
            email: (
              <Mailto
                email={t("researchEmail")}
                body=""
                subject={t("researchSubjectLine")}
                className="landingPage__link"
                withIcon={false}
              />
            ),
          }}
        >
          {t("ContactUs", {
            phoneNumber: t("researchTel"),
            emailAddress: t("researchEmail"),
          })}
        </Trans>
      </p>
    </div>
  );
};

interface BtnContainerProps extends Props {
  reverse?: boolean;
  className?: string;
}

// + BtnContainer (Btn Container to handle media queries for btns)
export const BtnContainer = ({ children, reverse = false, className = "" }: BtnContainerProps) => {
  const btnContainerStyles = (
    <div
      className={` ${
        reverse ? "flowPage__btnContainer--reverse" : ""
      } flowPage__btnContainer ${className}`}
    >
      {children}
    </div>
  );

  const handleBtnWithWrapper = GLOBAL_anchorButtonGroup ? (
    <div className="flowPage__btnWrapper">{btnContainerStyles}</div>
  ) : (
    btnContainerStyles
  );

  return handleBtnWithWrapper;
};

interface AgreementContainerProps {
  name: string;
  date: string;
}

export const AgreementContainer = ({ name, date }: AgreementContainerProps) => {
  const [t] = useTranslation("common");
  return (
    <div className="agreement">
      <p className="agreement__dateLine">
        <Trans>{t("agreedOn", { date: date })}</Trans>
      </p>
      <p className="agreement__nameLine">
        <Trans>{t("agreedBy", { user: name })}</Trans>
      </p>
    </div>
  );
};

export const EmailAlert = () => {
  const [t] = useTranslation("common");
  return (
    <div className="emailAlert__container">
      <p className="emailAlert__text">{t("emailAlertMsg")}</p>
    </div>
  );
};

export const PageSeperator = () => {
  return <div className="flowPage__seperator"></div>;
};

export default FlowPage;
