import { AxiosResponse } from "axios";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ParentConsentViewManager from "../../../../components/Managers/ParentConsentViewManager";
import { useRoute } from "../../../../context/RouteContext/RouteContext";
import { useUser } from "../../../../context/UserContext/UserContext";
import { routeScenarioEnum } from "../../../../hooks/RouteController/useRouteController";
import { useLeadingDebounce } from "../../../../hooks/utils/useDebounce";
import useRequestSpinner from "../../../../hooks/utils/useRequestSpinner";
import AssentPending from "../../../../modals/AssentPending";
import ConsentCollection from "../../../../modals/ConsentCollection";
import NoConsent from "../../../../modals/NoConsent";
import NoConsentAssent from "../../../../modals/NoConsentAssent";
import SharedData from "../../../../modals/SharedData";
import Button from "../../../../components/Button";
import Span from "../../../../components/Span";

interface ParentConsentProps {
  handleOnClickAgree: () => Promise<AxiosResponse<any>>;
  handleOnClickDisagree: () => Promise<AxiosResponse<any>>;
  handleAgreeNextPage: () => void;
  handleDisagreeNextPage: () => void;
}

const ParentConsent = ({
  handleOnClickAgree,
  handleOnClickDisagree,
  handleAgreeNextPage,
  handleDisagreeNextPage,
}: ParentConsentProps) => {
  const [noConsentModal, setNoConsentModal] = useState(false);
  const [dataCollectionModal, setDataCollectionModal] = useState(false);
  const [sharedDataModal, setSharedDataModal] = useState(false);

  const [assentPending, setAssentPending] = useState(false);
  const [consentAssentPending, setConsentAssentPending] = useState(false);

  const { routeScenario } = useRoute();

  const { parent, setParent, userDetails } = useUser();

  if (!parent) setParent(userDetails);

  const { handleRequestWithSpinner, requestLoading } = useRequestSpinner();

  const dataCollectionModalHandler = () => {
    setDataCollectionModal(true);
  };

  const handleSkipAgreement = () => {
    handleRequestWithSpinner(handleOnClickDisagree(), () => {
      if (routeScenario === routeScenarioEnum.d_mailChild) {
        setConsentAssentPending(true);
      } else {
        setNoConsentModal(true);
      }
    });
  };

  const handleNext = () => {
    handleRequestWithSpinner(handleOnClickAgree(), () => {
      if (routeScenario === routeScenarioEnum.d_mailChild) {
        setAssentPending(true);
      } else {
        setSharedDataModal(true);
      }
    });
  };

  const { t } = useTranslation();

  return (
    <ParentConsentViewManager
      feature_type="CONSENT_FLOW"
      requestLoading={requestLoading}
      parentName={parent?.name as string}
      button_primary={
        <Button className="btn btn--full-width" onClick={useLeadingDebounce(handleNext)}>
          {t("agreeFinish")}
        </Button>
      }
      button_secondary={
        <Button className="btn btn--outline btn--red" onClick={handleSkipAgreement}>
          {t("notAgree")}
        </Button>
      }
      button_data_collection={
        <Trans
          components={{
            a: <Span onClick={dataCollectionModalHandler} className="flowPage__links" />,
          }}
        >
          {t("parentConsentDesc2")}
        </Trans>
      }
      modals={[
        <ConsentCollection open={dataCollectionModal} setOpen={setDataCollectionModal} />,
        <NoConsent
          open={noConsentModal}
          setOpen={setNoConsentModal}
          onClickHandler={handleDisagreeNextPage}
        />,
        <NoConsentAssent
          open={consentAssentPending}
          setOpen={setConsentAssentPending}
          onClickHandler={handleDisagreeNextPage}
        />,
        <SharedData
          open={sharedDataModal}
          setOpen={setSharedDataModal}
          onClickHandler={handleAgreeNextPage}
        />,
        <AssentPending
          open={assentPending}
          setOpen={setAssentPending}
          onClickHandler={handleAgreeNextPage}
        />,
      ]}
    />
  );
};

export default ParentConsent;
