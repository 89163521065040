import {  useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import { IconEnum } from "../../../components/Icon";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import useRouteControl, {
  routeScenarioEnum,
} from "../../../hooks/RouteController/useRouteController";
import FlowPage, {
  Header,
  Body,
  Title,
  BtnContainer,
  ProgressBar,
} from "../FlowPage.wrapper";
import plus from "../../../images/plus.png";
import check from "../../../images/check-solid.png";
import ximage from "../../../images/icon_close.png";
import OrganizationLearnMoreModal from "../../../modals/OrganizationLearnMore";


const CareTeamOrganizationListEntry = ({
  role, 
  name,
  handleLearnMore,
  learnMoreContent,
}: {
  role: string, 
  name: string, 
  handleLearnMore: (title: string, content: string) => void,
  learnMoreContent: string
}) => {
  const [selected, setSelected] = useState(false);

  const handleClick = () => {
    setSelected((prevState) => !prevState);
  };

  return (
    <div className="flowPage__careTeamOrganization">
      <li className={`flowPage__listItem ${selected ? "unnotified" : "notified"}`}>
        <button onClick={handleClick}>
          <img src={selected ? check : ximage} alt="" />
          <p>{`${selected ? "Share" : "Do Not Share" }`}</p>
        </button>
        <div className="flowPage__p">
          <h4>{role}</h4>
          <button className="flowPage__careTeamOrgButton" onClick={() => handleLearnMore(name, learnMoreContent)}>
            {name}
          </button>
        </div>
      </li>
    </div>
  )
}

const CareTeamOrganizations = () => {
  const { t } = useTranslation();
  const { routeScenario } = useRoute();
  const history = useHistory();
  const [learnMoreModal, setLearnMoreModal] = useState(false);
  const [learnMoreContent, setLearnMoreContent] = useState("");
  const [learnMoreTitle, setLearnMoreTitle] = useState("");

  const [handleRoute] = useRouteControl(history);

  const handleNext = () => {
    handleRoute(routeScenario as routeScenarioEnum);
  };

  const goBack = () => {
    history.goBack();
  };

  const handleLearnMore = (title: string, content: string) => {
    setLearnMoreModal(true);
    setLearnMoreContent(content);
    setLearnMoreTitle(title);
  };


  return (
    <FlowPage pageName={CareTeamOrganizations.name}>
      <Header pathOverride="/about" />
      <ProgressBar level={3} description={t("progressBar3")} icon={IconEnum.beaker} />
      <div className="flowPage__padding">
        <Body>
          <Title>Care Team Organizations</Title>
          <Trans>
            <p className="flowPage__p">I (WE) hereby designate each of these persons/entities as members of our child’s Care Team. I (WE) hereby authorize:
            </p>
            <p className="flowPage__p">
              <ul className="flowPage__list">
                <li className="flowPage__list-element">
                the Baker Act facility to notify each of these members of this Baker Act admission, and
                </li>
                <li className="flowPage__list-element">
                the Baker Act facility and each of these and other Care Team members to share Education Information and Health Information for the Education Purposes and the Health Purposes with the Baker Act facility and with each of these and other Care Team members.
                </li>
              </ul>
            </p>
            <p className="flowPage__p">{t("caNotifyDesc3")}</p>
          </Trans>

          <div className="flowPage__careTeam">
            <CareTeamOrganizationListEntry 
              role="Care Coordination" 
              name="Broward Behavioural Health Coalition Provider"
              handleLearnMore={handleLearnMore}
              learnMoreContent={t("organizationBbhc")}
            />
            <CareTeamOrganizationListEntry 
              role="Dependency Youth" 
              name="Childnet"
              handleLearnMore={handleLearnMore}
              learnMoreContent={t("organizationChildNet")}
            />
            <CareTeamOrganizationListEntry 
              role="School Social Work and SEDNET" 
              name="Broward County Public Schools"
              handleLearnMore={handleLearnMore}
              learnMoreContent={t("organizationBrowardPublicSchools")}
            />
            <CareTeamOrganizationListEntry 
              role="Life Coach" 
              name="Health Youth Transition Program"
              handleLearnMore={handleLearnMore}
              learnMoreContent={t("organizationHealthyYouthTransitionProgram")}
            />
            <CareTeamOrganizationListEntry 
              role="Insurance Company Care Coordinator" 
              name="Community Cares Plan/Sunshine"
              handleLearnMore={handleLearnMore}
              learnMoreContent={t("organizationCommunityCaresPlan")}
            />
          </div>

          <BtnContainer reverse>
            <Button className="btn--outline">
              <img src={plus} alt="" />
              <p>Add a Care Team Organization</p>
            </Button>
            <Button className="btn--outline">Do Not Share</Button>
            <div className="button-navigation">
              <Button className="btn btn--primary" onClick={goBack}>
                {t("back")}
              </Button>
              <Button className="btn btn--primary" onClick={handleNext}>
                {t("next")}
              </Button>
            </div>
          </BtnContainer>
        </Body>
      </div>
      <OrganizationLearnMoreModal 
        open={learnMoreModal} 
        setOpen={setLearnMoreModal} 
        content={learnMoreContent}
        title={learnMoreTitle}
      />
    </FlowPage>
  );
};

export default CareTeamOrganizations;
