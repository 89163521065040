import { Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { isAuthenticated } from "./components/services/AuthService";
import AnalyticsService from "analytics-web";

// Route Utils
import {
  HOME,
  REDIRECT,
  CHOOSECHILD,
  CHOOSE_PARENT,
  FLOW_INTRO,
  FLOW_EXPLAINED,
  LIABILITY_RELEASE,
  DAMAGES_RELEASE,
  ONE_YEAR_TERM,
  REVOKE,
  FLOW_NEEDED,
  CHILD_ASSENT_PARENT_VIEW,
  PARENT_CONSENT_PARENT_VIEW,
  // PHN_PARENT,
  // PHN_PARENT_VIEW,
  DASHBOARD,
  PARENT_LEARN,
  ABOUT,
  PARENT_STOP,
  PDF,
  CHILD_FLOW_INTRO,
  CHILD_LEARN,
  CHILD_ASSENT_CHILD_VIEW,
  PARENT_CONSENT_CHILD_VIEW,
  // PHN_CHILD,
  // PHN_CHILD_VIEW,
  REVIEW_AGREEMENTS_ASSENT,
  REVIEW_AGREEMENTS_CONSENT,
  // REVIEW_AGREEMENTS_PHN,
  ERROR_NO_SESSION,
  ERROR_EXPIRED_SESSION,
  ERROR_NO_PROFILE,
  ERROR_CONSENT_NOT_NEEDED,
  ADULT_CONSENT,
  FLOW_NEEDED_ADULT,
  FLOW_EXPLAINED_ADULT_AND_BABY,
  NOTIFY_CARE_TEAM,
  // REVIEW_NOTIFY_CARE_TEAM,
  SHARE_HEALTH_INFO,
  // REVIEW_SHARE_HEALTH_INFO,
  REVIEW_AGREEMENTS_ADULT_CONSENT,
  // PHN_ADULT,
  // REVIEW_AGREEMENTS_PHN_ADULT_INFO,
  // REVIEW_AGREEMENTS_PHN_PARENT_INFO,
  // REVIEW_AGREEMENTS_PHN_CHILD_INFO,
  CARE_TEAM_ORGANIZATIONS
} from "./navigation/utils/RouteConstants";

import HomePage from "./navigation/pages/Landing/HomePage";
import RedirectPage from "./navigation/pages/RedirectPage";
import ChooseChild from "./navigation/pages/Flow/ChooseChild";
import ConsentAssentIntro from "./navigation/pages/Flow/ConsentAssentIntro";
import ConsentAssentExplained from "./navigation/pages/Flow/ConsentAssentExplained";
import NotifyCareTeam from "./navigation/pages/Flow/NotifyCareTeam";
import ShareHealthInfo from "./navigation/pages/Flow/ShareHealthInfo";
import LiabilityRelease from "./navigation/pages/Flow/LiabilityRelease";
import OneYearTerm from "./navigation/pages/Flow/OneYearTerm";
import Revoke from "./navigation/pages/Flow/Revoke";
import DamagesRelease from "./navigation/pages/Flow/DamagesRelease";
import ConsentAssentNeeded from "./navigation/pages/Flow/ConsentAssentNeeded";
import ChildAssentParentView from "./navigation/pages/Flow/ChildAssent/ChildAssentParentView";
import ParentConsentParentView from "./navigation/pages/Flow/ParentConsent/ParentConsentParentView";
import Dashboard from "./navigation/pages/Landing/Dashboard";
import ParentLearn from "./navigation/pages/Flow/ParentLearn";
import AboutPage from "./navigation/pages/Flow/AboutPage";
import ParentStop from "./navigation/pages/Flow/ParentStop";
import axios, { AxiosError } from "axios";
import { useState, useEffect } from "react";
import PDFViewer from "./navigation/pages/Landing/PDFViewer";
import ConsentAssentIntroChild from "./navigation/pages/Flow/ConsentAssentIntroChild";
import ChildLearn from "./navigation/pages/Flow/ChildLearn";
import ChildAssentChildView from "./navigation/pages/Flow/ChildAssent/ChildAssentChildView";
import ParentConsentChildView from "./navigation/pages/Flow/ParentConsent/ParentConsentChildView";
import Spinner from "./components/Spinner";

import { useRoute } from "./context/RouteContext/RouteContext";
import useRouteScenario from "./hooks/useRouteScenario";
import useRouteConditions from "./hooks/useRouteConditions";
import { useUser } from "./context/UserContext/UserContext";
import ChooseParent from "./navigation/pages/Flow/ChooseParent";
import ReviewAgreementsAssent from "./navigation/pages/Flow/ReviewAgreements/ReviewAgreementsAssent";
import ReviewAgreementsConsent from "./navigation/pages/Flow/ReviewAgreements/ReviewAgreementsConsent";
// import ReviewAgreementsPhn from "./navigation/pages/Flow/ReviewAgreements/ReviewAgreementsPhn";
// import ReviewAgreementsPhnParentInfo from "./navigation/pages/Flow/ReviewAgreements/ReviewAgreementsPhnParentInfo";
// import ReviewAgreementsPhnAdultInfo from "./navigation/pages/Flow/ReviewAgreements/ReviewAgreementsPhnAdultInfo";
import { ExpiredSessionErrorPage } from "./components/ErrorUI/ExpiredSessionErrorPage";
import { NoSessionErrorPage } from "./components/ErrorUI/NoSessionErrorPage";
import { NoProfileErrorPage } from "./components/ErrorUI/NoProfileErrorPage";
import { ConsentNotNeededErrorPage } from "./components/ErrorUI/ConsentNotNeededErrorPage";
import AdultConsent from "./navigation/pages/Flow/Adult-Patient/AdultConsent";
import ConsentAssentNeededAdult from "./navigation/pages/Flow/Adult-Patient/ConsentAssentNeededAdult";
import ConsentAssentExplainedAdult from "./navigation/pages/Flow/Adult-Patient/ConsentAssentExplainedAdultAndBaby";
import ReviewAgreementsAdultConsent from "./navigation/pages/Flow/ReviewAgreements/ReviewAgreementsAdultConsent";
// import PhnAdult from "./navigation/pages/Flow/Adult-Patient/PhnAdult";
// import ReviewAgreementsPhnChildInfo from "./navigation/pages/Flow/ReviewAgreements/ReviewAgreementsPhnChildInfo";
import { useErrorHandler } from "react-error-boundary";
import { AppError } from "./components/ErrorUI/types/ErrorType";
import { ConsentFlowRoute } from "./ConsentFlowRoute";
import CareTeamOrganizations from "./navigation/pages/Flow/CareTeamOrganizations";

export let BASE_URL = "";

function App() {
  const [appReady, setAppReady] = useState<any>();

  const { handleRouteScenario } = useRouteScenario();
  const [routeConditions] = useRouteConditions();
  const { isRouteConditionsReady, skipFlow, isRouteScenariosReady } = useRoute();
  const { mailRefer } = useUser();

  const handleError = useErrorHandler();

  // Intercept all Axios errors and use ErrorBoundary to force UI Fallback
  axios.interceptors.response.use(
    (res) => res,
    (error: AxiosError<AppError>) => {
      if (!error.response?.data.errorCode) return;

      return handleError(error.response?.data || { message: "NO_ERROR_CODE" });
    }
  );

  useEffect(() => {
    let cleanup = false;
    const startupConfigs = async () => {
      let startup = [];

      startup.push(
        new Promise((resolve, reject) => {
          axios.get("./config/config.json").then(function (res) {
            window.config = res.data;
            BASE_URL = res.data.url;
            resolve("Success");
          });
        })
      );

      return Promise.all(startup).then(function () {
        if (!cleanup) {
          // Analytics
          AnalyticsService.initialize(window.config.analytics_config.alias, {
            instrumentationKey: window.config.analytics_config.instrumentationKey,
            disableAjaxTracking: true,
          });

          setAppReady(true);
        }
      });
    };

    startupConfigs();

    return () => {
      cleanup = true;
    };
  }, [setAppReady]);

  useEffect(() => {
    if (isRouteConditionsReady && routeConditions) {
      switch (window.location.pathname) {
        case REDIRECT:
        case DASHBOARD:
        case FLOW_INTRO:
        case CHILD_FLOW_INTRO:
        case CHILD_LEARN:
        case PARENT_LEARN:
          return handleRouteScenario();
        default:
          return;
      }
    }
  }, [
    isRouteConditionsReady,
    isRouteScenariosReady,
    handleRouteScenario,
    routeConditions,
    mailRefer,
    skipFlow,
  ]);

  return appReady ? (
    <>
      <Switch>
        <Route exact path={HOME}>
          <HomePage />
        </Route>
        <ProtectedRoute exact path={DASHBOARD} component={Dashboard} />
        <ProtectedRoute exact path={PDF} component={PDFViewer} />
        <Route exact path={HOME}>
          <HomePage />
        </Route>
        <Route exact path={ABOUT}>
          <AboutPage />
        </Route>
        <Route exact path={REDIRECT}>
          <RedirectPage />
        </Route>
        <ConsentFlowRoute exact path={CHOOSECHILD}>
          <ChooseChild />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={FLOW_INTRO}>
          <ConsentAssentIntro />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={CHILD_FLOW_INTRO}>
          <ConsentAssentIntroChild />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={FLOW_EXPLAINED}>
          <ConsentAssentExplained />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={FLOW_EXPLAINED_ADULT_AND_BABY}>
          <ConsentAssentExplainedAdult />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={CARE_TEAM_ORGANIZATIONS}>
          <CareTeamOrganizations />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={NOTIFY_CARE_TEAM}>
          <NotifyCareTeam />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={SHARE_HEALTH_INFO}>
          <ShareHealthInfo />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={LIABILITY_RELEASE}>
          <LiabilityRelease />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={ONE_YEAR_TERM}>
          <OneYearTerm />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={REVOKE}>
          <Revoke />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={DAMAGES_RELEASE}>
          <DamagesRelease />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={FLOW_NEEDED}>
          <ConsentAssentNeeded />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={FLOW_NEEDED_ADULT}>
          <ConsentAssentNeededAdult />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={CHILD_ASSENT_PARENT_VIEW}>
          <ChildAssentParentView />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={CHILD_ASSENT_CHILD_VIEW}>
          <ChildAssentChildView />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={PARENT_CONSENT_PARENT_VIEW}>
          <ParentConsentParentView />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={PARENT_CONSENT_CHILD_VIEW}>
          <ParentConsentChildView />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={ADULT_CONSENT}>
          <AdultConsent />
        </ConsentFlowRoute>
        {/* <ConsentFlowRoute exact path={PHN_PARENT}>
          <PhnParent />
        </ConsentFlowRoute> */}
        {/* <ConsentFlowRoute exact path={PHN_ADULT}>
          <PhnAdult />
        </ConsentFlowRoute> */}
        {/* <ConsentFlowRoute exact path={PHN_CHILD}>
          <PhnChild />
        </ConsentFlowRoute> */}
        {/* <ConsentFlowRoute exact path={PHN_PARENT_VIEW}>
          <PhnParentView />
        </ConsentFlowRoute> */}
        {/* <ConsentFlowRoute exact path={PHN_CHILD_VIEW}>
          <PhnChildView />
        </ConsentFlowRoute> */}
        <ConsentFlowRoute exact path={PARENT_LEARN}>
          <ParentLearn />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={CHILD_LEARN}>
          <ChildLearn />
        </ConsentFlowRoute>
        <ConsentFlowRoute exact path={PARENT_STOP}>
          <ParentStop />
        </ConsentFlowRoute>
        {/* <Route exact path={REVIEW_NOTIFY_CARE_TEAM}>
          <ReviewNotifyCareTeam />
        </Route> */}
        {/* <Route exact path={REVIEW_SHARE_HEALTH_INFO}>
          <ReviewShareHealthInfo />
        </Route> */}
        <Route exact path={REVIEW_AGREEMENTS_ASSENT}>
          <ReviewAgreementsAssent />
        </Route>
        <Route exact path={REVIEW_AGREEMENTS_CONSENT}>
          <ReviewAgreementsConsent />
        </Route>
        <Route exact path={REVIEW_AGREEMENTS_ADULT_CONSENT}>
          <ReviewAgreementsAdultConsent />
        </Route>
        {/* <Route exact path={REVIEW_AGREEMENTS_PHN}>
          <ReviewAgreementsPhn />
        </Route> */}
        {/* <Route exact path={REVIEW_AGREEMENTS_PHN_PARENT_INFO}>
          <ReviewAgreementsPhnParentInfo />
        </Route> */}
        {/* <Route exact path={REVIEW_AGREEMENTS_PHN_ADULT_INFO}>
          <ReviewAgreementsPhnAdultInfo />
        </Route> */}
        {/* <Route exact path={REVIEW_AGREEMENTS_PHN_CHILD_INFO}>
          <ReviewAgreementsPhnChildInfo />
        </Route> */}
        <ConsentFlowRoute exact path={CHOOSE_PARENT}>
          <ChooseParent />
        </ConsentFlowRoute>
        <Route exact path={ERROR_EXPIRED_SESSION}>
          <ExpiredSessionErrorPage />
        </Route>
        <Route exact path={ERROR_NO_SESSION}>
          <NoSessionErrorPage />
        </Route>
        <Route exact path={ERROR_NO_PROFILE}>
          <NoProfileErrorPage />
        </Route>
        <Route exact path={ERROR_CONSENT_NOT_NEEDED}>
          <ConsentNotNeededErrorPage />
        </Route>

        <Route path="*">{isAuthenticated() ? <Dashboard /> : <HomePage />}</Route>
      </Switch>
    </>
  ) : (
    <Spinner />
  );
}

export default App;
