import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import { FLOW_NEEDED_ADULT, SHARE_HEALTH_INFO } from "../../utils/RouteConstants";

export const notifyCareTeamController = (
  types: RouteTypes,
  history: RouteComponentProps["history"]
) => {
  if (types.i_adult) history.push(FLOW_NEEDED_ADULT);
  else if (types.d_baby) history.push(SHARE_HEALTH_INFO);
};
