import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../types/RouteTypes";

import {
  DASHBOARD,
  PARENT_STOP,
  // REVIEW_AGREEMENTS_ADULT_CONSENT,
  REVIEW_AGREEMENTS_ASSENT,
  REVIEW_AGREEMENTS_CONSENT,
  ABOUTCONSENT,
  // NOTIFY_CARE_TEAM,
  REVIEW_NOTIFY_CARE_TEAM,
} from "../utils/RouteConstants";

export const redirectToPage = (history: RouteComponentProps["history"], redirectTo: string) => {
  history.replace(redirectTo);
};

export const goToStopParticipatingChildren = (history: RouteComponentProps["history"]) => {
  history.push(PARENT_STOP);
};

// TODO: Update this to push to AboutPage too.
// AboutPage will need to handle if it's coming through review agreements or consent flow
export const goToReviewAgremeents = (
  history: RouteComponentProps["history"],
  userGroup: string
) => {
  if (userGroup === "adult") {
    history.push(REVIEW_NOTIFY_CARE_TEAM);
  } else if (userGroup === "baby") {
    history.push(REVIEW_AGREEMENTS_CONSENT);
  } else history.push(REVIEW_AGREEMENTS_ASSENT);
};

//TODO: Update this to point to AboutPage. All user cases should now start at AboutPage
export const redirectController = (types: RouteTypes, history: RouteComponentProps["history"]) => {
  history.replace(DASHBOARD);
  const { d_child, d_missingConsent, d_baby, i_child, i_missingAssent, i_adult } = types;
  if (d_child || d_missingConsent || d_baby || i_child || i_missingAssent || i_adult)
    history.push(ABOUTCONSENT);
};
