import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import { FLOW_NEEDED_ADULT, LIABILITY_RELEASE } from "../../utils/RouteConstants";

export const careTeamOrganizationsController = (
  types: RouteTypes,
  history: RouteComponentProps["history"]
) => {
  if (types.i_adult) history.push(FLOW_NEEDED_ADULT);
  else if (types.d_baby) history.push(LIABILITY_RELEASE);
};
