import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Link,
  // Route
} from "react-router-dom";
import Mailto from "../../components/Mailto";
import Phoneto from "../../components/Phoneto";
import { HOME, PDF } from "../utils/RouteConstants";
import { formatDate, formatDateAndAddOneYear } from "../../utils/formatTime";
import Icon, { IconEnum } from "../../components/Icon";
import { CookieStorage } from "../../components/services/Storage";
import { logout } from "../../API/Endpoints";
import { sendEmail } from "../../API/EmailEndpoint";
import { useUser } from "../../context/UserContext/UserContext";
import { UserRelated, UserRole, UserTypes } from "../../context/UserContext/UserTypes";
import { isLoggedInParentConsenter } from "../../services/FilterParent";
import EmailAssent from "../../modals/EmailAssent";
import EmailConsent from "../../modals/EmailConsent";
import { isThisPhnValid } from "../../utils/phnValidation";
import { isAdultPatient, isChild } from "../../utils/handleChildAge";
// import { isParent, isPatient } from "../../utils/isUser";
import EmailAdultConsent from "../../modals/EmailAdultConsent";
import { UserType } from "./Landing/PDFViewer";
import TrackAnalytics from "../../components/Analytics/TrackAnalytics";
import { useUtil } from "../../context/UtilContext/UtilContext";
import Button from "../../components/Button";
import plus from "../../images/plus.png";

/*
Landing Page Wrapper

- A page that is shown to the user when lands.
- This Page wrapper consists of multiple segments that a Page can
    inject strings into.
- Section can also have news cards when authenticated

* HeaderLanding (text) -> component lives here, changes text based on
   component being used in.
+ PageLanding (wrapper)
+ Header(title, subtitle)
+ Status ([name, expiraryDate]
* Section (anything): card that alternatives from blue to white bg


*/

export interface Props {
  children?: React.ReactNode;
  className?: string;
}

interface LandingPageProps extends Props {
  pageName: any;
}

const LandingPage = ({ children, className = "", pageName }: LandingPageProps) => {
  const { pageName: globalPageName } = useUtil();

  globalPageName.current = pageName;

  useEffect(() => {
    TrackAnalytics("on_load", "user has loadded page component", pageName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="landing-app">
      <div className={`${className} landingPage`}>{children}</div>
    </div>
  );
};

interface HeaderProps extends Props {
  title: string;
  subtitle: string;
  withLogoutButton?: boolean;
  withLoginButton?: false;
  loginButtonOnClickHandler?: () => void;
}
interface HeaderProps2 extends Props {
  title: string;
  subtitle: string;
  withLogoutButton?: boolean;
  withLoginButton?: true;
  loginButtonOnClickHandler: () => void;
}

export const Header = ({
  title,
  subtitle,
  className = "",
  withLogoutButton = false,
  withLoginButton,
  loginButtonOnClickHandler,
}: HeaderProps | HeaderProps2) => {
  let handleButtonView;

  if (withLoginButton && loginButtonOnClickHandler) {
    handleButtonView = <LoginButton onClickHandler={loginButtonOnClickHandler} />;
  } else if (withLogoutButton) {
    handleButtonView = <LogoutButton />;
  }

  return (
    <div data-testid="LandingPage-Header" className={`${className} landingPage__header`}>
      <div className="landingPage__headerImg">
        {handleButtonView}
        <div className="landingPage__headerBox">
          <h1 className="LandingPage__headerTitle">{title}</h1>
          <p className="LandingPage__headerSubtitle">{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export const HomepageHeader = ({
  title,
  subtitle,
  className = "",
  withLogoutButton = false,
  withLoginButton,
  loginButtonOnClickHandler,
}: HeaderProps | HeaderProps2) => {
  let handleButtonView;

  if (withLoginButton && loginButtonOnClickHandler) {
    handleButtonView = <LoginButton onClickHandler={loginButtonOnClickHandler} />;
  } else if (withLogoutButton) {
    handleButtonView = <LogoutButton />;
  }

  return (
    <div data-testid="homePage-Header" className={`${className} homePage__header`}>
      <div className="homePage__headerImg"></div>
      <div className="homePage__headerBox">
        <h1 className="homePage__headerTitle">{title}</h1>
        <p className="homePage__headerSubtitle">{subtitle}</p>
      </div>
      {handleButtonView}
    </div>
  );
};

interface SectionProps extends Props {
  oddOrder?: boolean;
}

export const Section = ({ children, className = "", oddOrder = false }: SectionProps) => (
  <div
    data-testid="LandingPage-Section"
    className={`${className} landingPage__section ${oddOrder ? "landingPage__section--odd" : ""}`}
  >
    <div className="landingPage__innerSection">{children}</div>
  </div>
);
export const Caption = ({ children, className = "" }: Props) => (
  <div data-testid="LandingPage-Caption" className={`${className} landingPage__caption`}>
    {children}
  </div>
);

export const ContactUs = () => {
  const [t] = useTranslation("common");
  return (
    <div className="text--center">
      <h2 className="homePage__title">{t("homePageQ")}</h2>
      <p className="homePage__p">
        <Trans
          components={{
            phone: <Phoneto phoneNumber={t("researchTel")} className="landingPage__link" />,
            email: (
              <Mailto
                email={t("researchEmail")}
                body=""
                subject={t("researchSubjectLine")}
                className="landingPage__link"
                withIcon={false}
              />
            ),
          }}
        >
          {t("ContactUs", {
            phoneNumber: t("researchTel"),
            emailAddress: t("researchEmail"),
          })}
        </Trans>
      </p>
      <p className="homePage__p">
        <Trans
          components={{
            localPhone: <Phoneto phoneNumber={t("complaintTel")} className="landingPage__link" />,
            globalPhone: (
              <Phoneto phoneNumber={t("complaintLongDistanceTel")} className="landingPage__link" />
            ),
            email: (
              <Mailto
                email={t("complaintLongDistanceEmail")}
                body=""
                subject={t("complaintSubjectLine")}
                className="landingPage__link"
                withIcon={false}
              />
            ),
          }}
        >
          {t("homePageQDesc2", {
            localNumber: t("complaintTel"),
            longDistanceNumber: t("complaintLongDistanceTel"),
            longDistanceEmail: t("complaintLongDistanceEmail"),
          })}
        </Trans>
      </p>
      <p className="homePage__p">
        <Trans>{t("homePageQDesc3")}</Trans>
      </p>
    </div>
  );
};

export const Footer = ({ children, className = "" }: Props) => {
  const [t] = useTranslation("common");
  // const { pageName } = useUtil();

  return (
    <div data-testid="LandingPage-Caption" className={`${className} landingPage__footer`}>
      <h2>{t("footerHeader")}</h2>
      <p>{t("footerContent")}</p>
      {/* <a
        href={t("footerLinkToS")}
        className="footer__link"
        onClick={(e) => {
          TrackAnalytics(e.type, t("ToS"), pageName.current);
        }}
      >
        {t("ToS")}
      </a>
      <a
        href={t("footerLinkPrivacy")}
        className="footer__link"
        onClick={(e) => {
          TrackAnalytics(e.type, t("footerLinkPrivacy"), pageName.current);
        }}
      >
        {t("Privacy")}
      </a> */}
    </div>
  );
};

export const AgreementSection = ({ children, className = "" }: Props) => (
  <div className={`${className} landingPage__agreement`}>
    <div className="landingPage__innerAgreement">{children}</div>
  </div>
);

interface AgreementRecordProps {
  user: UserTypes | UserRelated;
  isParent?: boolean;
  isAdultPatient?: boolean;
}

export const AgreementRecord = ({
  user,
  isParent = false,
  isAdultPatient = false,
}: AgreementRecordProps) => {
  const [emailConsentModal, setEmailConsentModal] = useState(false);
  const [emailAdultConsentModal, setEmailAdultConsentModal] = useState(false);
  const [emailAssentModal, setEmailAssentModal] = useState(false);

  const { userDetails, parent, children } = useUser();

  const { name, agreementDate, identifier } = user;

  // Date variables
  const formattedExpiryDate = formatDateAndAddOneYear(agreementDate);
  const formattedAgreedDate = formatDate(agreementDate);

  const [t] = useTranslation("common");

  const hasChildAgreedPHN = () => {
    const child = children[0];
    return isThisPhnValid(child.phn);
  };

  const onClickDownloadHandler = () => {
    console.log(`print records for ${name}`);
  };
  const onClickEmailHandler = () => {
    const token = CookieStorage.get("token") as string;
    sendEmail(identifier, userDetails.email, token);

    if (isParent) {
      setEmailConsentModal(true);
    } else if (isAdultPatient) {
      setEmailAdultConsentModal(true);
    } else {
      setEmailAssentModal(true);
    }
  };

  const checkConsentedParent = () => {
    if (isLoggedInParentConsenter(userDetails, parent)) {
      return t("homeFullAgreement__consentTitle-parent");
    } else {
      return parent?.name;
    }
  };

  const parentConsentTitleHandler = () => {
    if (isAdultPatient) {
      return t("homeFullAgreement__consentTitle-adult");
    }

    if (!isParent) {
      return name;
    }

    if (userDetails.role === UserRole.PARENT) {
      return checkConsentedParent();
    } else {
      return t("homeFullAgreement__consentTitle-child");
    }
  };

  const handleUserType = (): UserType => {
    if (isParent) {
      return "parent";
    } else if (isAdultPatient) {
      return "adult_patient";
    } else if (isChild(children[0].age)) return "child";
    else {
      return "none";
    }
  };

  return (
    <>
      <div className={`agreement`}>
        <p className="agreement__name">{parentConsentTitleHandler()}</p>
        <div className="agreement__box">
          <p className="agreement__date">
            {t("homeFullAgreementExpiry", { date: formattedExpiryDate })}
          </p>
          <div className="">
            <Link
              onClick={onClickDownloadHandler}
              className="landingPage__link landingPage__link--small"
              to={{
                pathname: PDF,
                hash: `#name=${name}&date=${formattedAgreedDate}&userType=${handleUserType()}&hasChildAgreedPHN=${hasChildAgreedPHN()}`,
              }}
            >
              {t("homeFullAgreementDownload")}
            </Link>
            <Button
              onClick={onClickEmailHandler}
              className="landingPage__link landingPage__link--small ml-10"
            >
              {t("homeFullAgreementEmail")}
            </Button>
          </div>
        </div>
      </div>
      <EmailAssent open={emailAssentModal} setOpen={setEmailAssentModal} />
      <EmailConsent open={emailConsentModal} setOpen={setEmailConsentModal} />
      <EmailAdultConsent open={emailAdultConsentModal} setOpen={setEmailAdultConsentModal} />
    </>
  );
};

interface StopParticipatingSectionProps {
  onClickHandler: () => void;
}

export const StopParticipatingButton = ({ onClickHandler }: StopParticipatingSectionProps) => {
  const { parent, userDetails } = useUser();
  const [t] = useTranslation("common");

  const checkRenderConditions = () => {
    const isAdult = isAdultPatient(userDetails.age, userDetails.role as UserRole);

    return isLoggedInParentConsenter(userDetails, parent) || isAdult;
  };

  // const disclaimerText = () => {
  //   if (isParent(userDetails.role)) {
  //     return t("HomeFullAgreementDisclaimer-parent");
  //   } else if (isPatient(userDetails.role)) {
  //     return t("HomeFullAgreementDisclaimer-patient");
  //   } else {
  //     return "";
  //   }
  // };

  return (
    <div className="agreement__disclaimer">
      {checkRenderConditions() && (
        <>
          <Button onClick={onClickHandler} className="btn btn--full-width">
            {t("homeFullAgreementBtn")}
          </Button>
          {/* <p className="agreement__disclaimerText">{disclaimerText()}</p> */}
        </>
      )}
    </div>
  );
};

export const LogoutButton = () => {
  const [t] = useTranslation("common");

  const onClickHandler = () => {
    console.log(`clear credentials, and logout`);
    logout(CookieStorage.get("token") as string);
    // useLogoutQuery(SessionStorage.get("token") as string)
    CookieStorage.remove("token");
  };

  return (
    <div className={`logout`}>
      <Link to={HOME} onClick={onClickHandler} className="logout__link">
        <p className="logout__text">{t("logout")}</p>
        {/* <img src={logoutImg} className={"logout__icon"} /> */}
        <Icon iconName={IconEnum.logout} className={"logout__icon"} withIconClass={false} />
      </Link>
    </div>
  );
};

interface LoginButtonProps {
  onClickHandler: () => void;
}

export const LoginButton = ({ onClickHandler }: LoginButtonProps) => {
  const [t] = useTranslation("common");

  return (
    <div className={`logout`}>
      <Button onClick={onClickHandler} className="logout__link logout__text">
        {t("homePageMyDashboard")}
      </Button>
    </div>
  );
};

export const ConsentFormButton = ({ status }: any, onClickHandler: () => void) => {
  const buttonTitle = status === "fullAgreement" ? "EDIT" : "ADD";

  return (
    <li className={"landingPage__consentItem"}>
      <button onClick={onClickHandler}>
        <img src={plus} alt="" />
        <p>{buttonTitle}</p>
      </button>
      <div className="landingPage__p">
        <h4>Baker Act Parental Consent</h4>
        <p>Parent Consent to Notify CARE Team Members and Share CARE Team Member Information</p>
      </div>
    </li>
  );
};

export default LandingPage;
