import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import { IconEnum } from "../../../components/Icon";
import BakerAct from "../../../modals/BakerAct";
import Ferpa from "../../../modals/Ferpa";
import Hippa from "../../../modals/Hippa";
import FlowPage, {
  Body,
  // Title,
  // Desc,
  BtnContainer,
  Header,
  AcceptAlertContainer,
  CaptionContainer,
  ProgressBar,
} from "../FlowPage.wrapper";
import useRouteControl, {
  routeScenarioEnum,
} from "../../../hooks/RouteController/useRouteController";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import { useUser } from "../../../context/UserContext/UserContext";
import { goToReviewAgremeents } from "../../../navigation/routing/redirectPage.logic";

// TODO: Update this to use handleRoute Hook. HandleRoute will trigger the conditions based on the user logging in.
const AboutPage = () => {
  const [bakerAct, setBakerAct] = useState(false);
  const [ferpa, setFerpa] = useState(false);
  const [hippa, setHippa] = useState(false);
  const [t] = useTranslation();
  const history = useHistory();
  const { routeScenario } = useRoute();
  const { userDetails } = useUser();
  const [handleRoute] = useRouteControl(history);
  const [displayAgreement, setDisplayAgreement] = useState(false);
  const hash = history.location.hash.split("consentReview=")[1];

  useEffect(() => {
    setDisplayAgreement(hash === "review");
  }, [hash]);

  const handleRedirect = () => {
    if (hash === "review") goToReviewAgremeents(history, userDetails.userGroup || "");
    else handleRoute(routeScenario as routeScenarioEnum);
  };

  const handleLearnMore = () => {
    setBakerAct(true);
  };
  const handleFerpa = () => {
    setFerpa(true);
  };
  const handleHippa = () => {
    setHippa(true);
  };

  const handleBack = () => {
    history.goBack();
  };
  return (
    <FlowPage anchorButtonGroup={false} pageName={AboutPage.name}>
      <Header />
      <ProgressBar level={0.2} description={t("progressBarIntro")} icon={IconEnum.beaker} />
      <div className="flowPage__padding">
        <Body>
          {displayAgreement && (
            <AcceptAlertContainer>{t("reviewAgreementAlertText")}</AcceptAlertContainer>
          )}
          <CaptionContainer>
            <p className="flowPage__caption">{t("caAboutCaption")}</p>
          </CaptionContainer>
          <Trans>
            {/* <h2 className="flowPage__subtitle">{t("aboutSubtitle1")}</h2> */}
            <p className="flowPage__p--last">{t("aboutDesc1")}</p>
            <p className="flowPage__p--last">{t("aboutDesc2")}</p>
            <ol className="flowPage__orderList">
              <li className="flowPage__orderListItem">{t("aboutDesc2-1")}</li>
              <li className="flowPage__orderListItem">{t("aboutDesc2-2")}</li>
              <li className="flowPage__orderListItem">{t("aboutDesc2-3")}</li>
            </ol>
            <p className="flowPage__p--last">{t("aboutDesc3")}</p>

            <Button className="aboutPage__links" onClick={handleLearnMore}>
              {t("aboutDesc4C-Link")}
            </Button>
            <Button className="aboutPage__links" onClick={handleFerpa}>
              {t("aboutDesc4C-Link2")}
            </Button>
            <Button className="aboutPage__links" onClick={handleHippa}>
              {t("aboutDesc4C-Link3")}
            </Button>
          </Trans>
          <BtnContainer reverse>
            <Button className="btn btn--full-width" onClick={handleRedirect}>
              {displayAgreement ? t("next") : t("next")}
            </Button>
            <Button className="btn--outline" onClick={handleBack}>
              {displayAgreement ? t("reviewAgreementBtnSecondaryReturnHome") : t("back")}
            </Button>
          </BtnContainer>
        </Body>
      </div>
      <BakerAct open={bakerAct} setOpen={setBakerAct} />
      <Ferpa open={ferpa} setOpen={setFerpa} />
      <Hippa open={hippa} setOpen={setHippa} />
    </FlowPage>
  );
};

export default AboutPage;
