import { useEffect } from "react";
import { useHistory } from "react-router";

// Context
import { useUser } from "../../context/UserContext/UserContext";

//Routing Logic
import { redirectToPage } from "../routing/redirectPage.logic";
import { handleMultiChildInRoute } from "../routing/Flow/about.logic";
import { useErrorHandler } from "react-error-boundary";

// Machines
import { useFetchUser } from "../../context/UserContext/UserFetchContext";
import useRouteControl from "../../hooks/RouteController/useRouteController";
import { useRoute } from "../../context/RouteContext/RouteContext";
import { DASHBOARD } from "../utils/RouteConstants";
import useUserService from "../../hooks/useUserService";

import Spinner from "../../components/Spinner";

interface RedirectPageProps {
  redirectTo?: string;
}

const RedirectPage = ({ redirectTo }: RedirectPageProps) => {
  const handleError = useErrorHandler();
  const history = useHistory();

  const [handleRoute] = useRouteControl(history);

  const { userDetails, children } = useUser();
  const { routeScenario, skipFlow, isRouteScenariosReady } = useRoute();

  const { error, isUserReady } = useFetchUser();

  // Trigger UserService Call
  useUserService();

  useEffect(() => {
    if (isRouteScenariosReady && skipFlow) {
      history.push(DASHBOARD);
    }
  }, [history, isRouteScenariosReady, skipFlow]);

  useEffect(() => {
    if (isUserReady) {
      if (redirectTo) {
        redirectToPage(history, redirectTo);
      } else {
        handleMultiChildInRoute(children);
        handleRoute(routeScenario);
      }
    }
  }, [
    userDetails,
    error,
    isUserReady,
    history,
    redirectTo,
    handleError,
    children,
    handleRoute,
    routeScenario,
    isRouteScenariosReady,
    skipFlow,
  ]);
  return <Spinner />;
};

export default RedirectPage;
