import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { updateAgreementUserChild } from "../../../../API/Endpoints";
import Button from "../../../../components/Button";
import { IconEnum } from "../../../../components/Icon";
import { CookieStorage } from "../../../../components/services/Storage";
import { useUser } from "../../../../context/UserContext/UserContext";
import { useLeadingDebounce } from "../../../../hooks/utils/useDebounce";
import ConsentCollection from "../../../../modals/ConsentCollection";
import NoAdultConsent from "../../../../modals/NoAdultConsent";
import SharedData from "../../../../modals/SharedData";
import { FirstName } from "../../../../utils/SplitName";
import { getDateNow } from "../../../../utils/TimeStampInSeconds";
import { DASHBOARD } from "../../../utils/RouteConstants";
import FlowPage, {
  ProgressBar,
  Body,
  Title,
  Desc,
  CheckList,
  CheckListItem,
  BtnContainer,
  Header,
  ContactUs,
  EmailAlert,
} from "../../FlowPage.wrapper";

const AdultConsent = () => {
  const [noConsentModal, setNoConsentModal] = useState(false);
  const [dataCollectionModal, setDataCollectionModal] = useState(false);
  const [sharedDataModal, setSharedDataModal] = useState(false);
  const { t } = useTranslation();

  const { userDetails, setUserDetails } = useUser();

  const history = useHistory();

  // * Handle Agremeents

  const handleAgreeNextPage = () => {
    history.push(DASHBOARD);
  };

  const handleDisagreeNextPage = () => {
    history.push(DASHBOARD);
    console.log("leaving flow");
  };

  const handleSkipAgreement = () => {
    // Update calls
    updateConsent(false);
    setNoConsentModal(true);
  };

  const handleNext = () => {
    // Update calls
    updateConsent(true);
    setSharedDataModal(true);
  };

  const dataCollectionModalHandler = () => {
    setDataCollectionModal(true);
  };

  // Helper Fns
  const updateConsent = (consent: boolean) => {
    const adultUser = userDetails;
    adultUser.consent = consent;
    adultUser.hasAgreedBefore = true;
    adultUser.agreementDate = consent ? getDateNow() : 0;

    adultUser.consentBy = [adultUser.identifier];

    updateAgreementUserChild(CookieStorage.get("token") as string, adultUser);

    setUserDetails(adultUser);
  };

  return (
    <FlowPage anchorButtonGroup={false} pageName={AdultConsent.name}>
      <Header />
      <ProgressBar level={4} description={t("progressBar3C")} icon={IconEnum.document} />
      <Body>
        <Desc>
          <Title>
            {t("adultConsentTitle", {
              adult: FirstName(userDetails.name as string),
            })}
          </Title>
          <p className="flowPage__p flowPage__p--policy">{t("adultConsentDesc1")}</p>
          <p className="flowPage__p flowPage__p--policy">
            <Trans
              components={{
                a: <Button onClick={dataCollectionModalHandler} className="flowPage__links" />,
              }}
            >
              {t("adultConsentDesc2")}
            </Trans>
          </p>
          <p className="flowPage__p flowPage__p--policy">
            <Trans>{t("adultConsentDesc3")}</Trans>
          </p>

          <CheckList>
            <CheckListItem>{t("adultConsentList1")}</CheckListItem>
            <CheckListItem>{t("adultConsentList2")}</CheckListItem>
            <CheckListItem>{t("parentConsentList3")}</CheckListItem>
            <CheckListItem>{t("adultConsentList4")}</CheckListItem>
            <CheckListItem>{t("parentConsentList5")}</CheckListItem>
            <CheckListItem>{t("parentConsentList7")}</CheckListItem>
            <CheckListItem>{t("parentConsentList8")}</CheckListItem>
            <CheckListItem>{t("parentConsentList9")}</CheckListItem>
            <CheckListItem>{t("parentConsentList10")}</CheckListItem>
          </CheckList>
          <EmailAlert />
          <ContactUs />
        </Desc>
        <BtnContainer reverse>
          <Button className="btn" onClick={useLeadingDebounce(handleNext)}>
            {t("adultConsentAgree")}
          </Button>
          <Button className="btn" onClick={useLeadingDebounce(handleSkipAgreement)}>
            {t("adultConsentDisagree")}
          </Button>
        </BtnContainer>
      </Body>
      <ConsentCollection open={dataCollectionModal} setOpen={setDataCollectionModal} />
      <SharedData
        open={sharedDataModal}
        setOpen={setSharedDataModal}
        onClickHandler={handleAgreeNextPage}
        isAdult={true}
      />
      <NoAdultConsent
        open={noConsentModal}
        setOpen={setNoConsentModal}
        onClickHandler={handleDisagreeNextPage}
      />
    </FlowPage>
  );
};

export default AdultConsent;
