import { useEffect, useState } from "react";
import HomeAuthParentController from "../../../components/HomeController/DashboardCardController/ParentController";
import { useTranslation } from "react-i18next";
import LandingPage, { Header, HomepageHeader, Section, Footer } from "../LandingPage.wrapper";
import { ResearchHeadlineProps } from "../../../components/ResearchCard";
import axios from "axios";
import { BASE_URL } from "../../../App";
import { useUser } from "../../../context/UserContext/UserContext";
import { UserRole } from "../../../context/UserContext/UserTypes";
import HomeAuthChildController from "../../../components/HomeController/DashboardCardController/ChildController";
import { isAdultPatient } from "../../../utils/handleChildAge";
import AdultPatientView from "../../../components/HomeController/DashboardCardController/AdultPatient/AdultPatientView";
import { AdultPatientCardProvider } from "../../../components/HomeController/DashboardCardController/AdultPatient/AdultPatientCardController";
import useUserService from "../../../hooks/useUserService";
import { useFetchUser } from "../../../context/UserContext/UserFetchContext";
import { CookieStorage } from "../../../components/services/Storage";
import Spinner from "../../../components/Spinner";
import { useInterceptBackButton } from "../../../hooks/utils/useInterceptBackButton";

const Dashboard = () => {
  const [researchHeadlines, setResearchHeadlines] = useState<Array<ResearchHeadlineProps>>();
  const [t] = useTranslation("common");
  const { setMailRefer, userDetails } = useUser();
  // const { fullAgreement } = useUtil();
  const { isUserReady, refreshUserDetails } = useFetchUser();

  useUserService();
  useInterceptBackButton({ logout: true });

  console.log(researchHeadlines);

  useEffect(() => {
    refreshUserDetails(CookieStorage.get("token") as string);
  }, [refreshUserDetails]);

  // const adultPatientFullAgreement =
  //   isAdultPatient(userDetails.age, userDetails.role) && userDetails.consent;

  // const Title = () => {
  //   if (fullAgreement.length || adultPatientFullAgreement) {
  //     return ParentOrChildText();
  //   } else {
  //     return t("homeAuthTitle-Not");
  //   }
  // };

  // const ParentOrChildText = () => {
  //   if (isParent(userDetails.role)) {
  //     return t("homeAuthTitle-Parent");
  //   } else {
  //     return t("homeAuthTitle-Child");
  //   }
  // };

  const ChildOrParent = () => {
    if (UserRole.PARENT === userDetails.role) {
      return <HomeAuthParentController />;
    } else if (isAdultPatient(userDetails.age, userDetails.role)) {
      return (
        <AdultPatientCardProvider>
          <AdultPatientView />
        </AdultPatientCardProvider>
      );
    } else if (UserRole.PATIENT === userDetails.role) {
      return <HomeAuthChildController />;
    }
  };

  useEffect(() => {
    setMailRefer(false);
  }, [setMailRefer]);

  useEffect(() => {
    let cleanup = false;

    // Use token to fetch a user then redirect user based on the data returned.
    async function fetchData() {
      console.log(`${BASE_URL}/users/researchHeadlines`);
      let res = await axios({
        /*Add localhost URL to fetch local endpoint here here-> */

        url: "./config/config.json",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      if (!cleanup) {
        setResearchHeadlines(res.data.researchHeadlines);
      }
    }

    fetchData();

    return () => {
      cleanup = true;
    };
  }, []);

  // const handleResearchSubtext = () => {
  //   if (isChild(userDetails.age)) {
  //     return t("homeAuthDesc-Research");
  //   } else if (isParent(userDetails.role)) {
  //     return t("homeAuthDesc-Research-parent");
  //   } else {
  //     return t("homeAuthDesc-Research-adult");
  //   }
  // };

  const userFirstName = userDetails.name.split(" ")[0];

  return isUserReady ? (
    <LandingPage pageName={Dashboard.name}>
      <HomepageHeader title={t("title")} subtitle={t("subtitle")} withLogoutButton={false} />
      <Header
        title={`Welcome ${userFirstName}!`}
        subtitle={t("homeAuthDesc-Parent")}
        withLogoutButton={true}
      />
      <Section oddOrder={true}>{ChildOrParent()}</Section>

      <Section className="user-details">
        <div className="landingPage__userDetails">
          <div className="userTitle">
            <h3>Your Information</h3>
            <button>EDIT</button>
          </div>
          <h5>Review the information about you that may be attached to a consent form</h5>
          <div>
            <h4>Name</h4>
            <p>{userDetails.name}</p>
          </div>
          <div>
            <h4>Email</h4>
            <p>{userDetails.email}</p>
          </div>
          {/* <div>
          <h4>Address</h4>
          <p>{userDetails.age}</p>
        </div>
        <div>
          <h4>Phone</h4>
          <p>{userDetails.age}</p>
        </div> */}
          <div>
            <h4>Dependent</h4>
            <p>{userDetails.related[0].name}</p>
          </div>
        </div>
      </Section>
      <Footer />
    </LandingPage>
  ) : (
    <Spinner />
  );
};

export default Dashboard;
