import { useTranslation } from "react-i18next";
import Modal, { BaseModalProps } from "./Modal";
import Button from "../components/Button";

const BakerAct = ({ open, setOpen }: BaseModalProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content scrollbox>
        <Modal.Title>{t("dataColectionTitle")}</Modal.Title>
        <Modal.Desc textLeft>
          <p className="flowPage__p">{t("bakerActDesc1")}</p>
          <p className="flowPage__p">{t("bakerActDesc2")}</p>
          <ul className="flowPage__bullet">
            <li className="flowPage__bulletItems">{t("bakerActDesc2-1")}</li>
            <li className="flowPage__bulletItems">{t("bakerActDesc2-2")}</li>
            <li className="flowPage__bulletItems">{t("bakerActDesc2-3")}</li>
          </ul>
          <p className="flowPage__p">{t("bakerActDesc3")}</p>
          <p className="flowPage__p">{t("bakerActDesc4")}</p>
        </Modal.Desc>
      </Modal.Content>
      <Modal.BtnContainer scrollbox>
        <Button onClick={() => handleClick()} className="btn">
          {t("gotIt")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};

/*
Fix spacing so this modal is ontop of last
*/

export default BakerAct;
