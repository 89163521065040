import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";
import { IconEnum } from "../../../../components/Icon";
import { ADULT_CONSENT } from "../../../utils/RouteConstants";
import FlowPage, {
  ProgressBar,
  Body,
  Title,
  Desc,
  CaptionContainer,
  BtnContainer,
  Header,
} from "../../FlowPage.wrapper";

const ConsentAssentNeededAdult = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleNext = () => {
    history.push(ADULT_CONSENT);
  };
  const handleBackBtn = () => {
    history.goBack();
  };

  return (
    <FlowPage pageName={ConsentAssentNeededAdult.name}>
      <Header />
      <ProgressBar level={2} description={t("progressBar2")} icon={IconEnum.beaker} />
      <Body>
        <Title>{t("caNeededTitle")}</Title>
        <Desc>
          <p className="flowPage__p">{t("caNeededDesc1-adult")}</p>
          <p className="flowPage__p">
            <Trans>{t("caNeededDesc2-adult")}</Trans>
          </p>
          <CaptionContainer>
            <p className="flowPage__caption">{t("caNeededCaption")}</p>
          </CaptionContainer>
        </Desc>
        <BtnContainer reverse>
          <Button className="btn" onClick={handleNext}>
            {t("seeAgreement")}
          </Button>
          <Button onClick={handleBackBtn} className="btn btn--secondary">
            {t("back")}
          </Button>
        </BtnContainer>
      </Body>
    </FlowPage>
  );
};

export default ConsentAssentNeededAdult;
