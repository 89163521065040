import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useUser } from "../../../context/UserContext/UserContext";
import Button from "../../../components/Button";
import { IconEnum } from "../../../components/Icon";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import { getCareTeam } from "../../../API/Endpoints";
import useRouteControl, {
  routeScenarioEnum,
} from "../../../hooks/RouteController/useRouteController";
import FlowPage, {
  Header,
  Body,
  Title,
  Desc,
  BtnContainer,
  ProgressBar,
  PractitionerShareList,
  Practitioner,
} from "../FlowPage.wrapper";
import plus from "../../../images/plus.png";

const ShareHealthInfo = () => {
  const { t } = useTranslation();
  const { routeScenario } = useRoute();
  const history = useHistory();
  const { parent } = useUser();

  const [handleRoute] = useRouteControl(history);

  const handleNext = () => {
    handleRoute(routeScenario as routeScenarioEnum);
  };

  const goBack = () => {
    history.goBack();
  };

  const [practitionersToShare, setPractitionersToShare] = useState<any[]>([]);

  const addToPractitionersToShare = (member: any) => {
    setPractitionersToShare((prevMembers) => {
      const isMemberInList = prevMembers.some((prevMember) => prevMember.name === member.name);

      if (isMemberInList) {
        // Remove the member from the list
        const updatedMembers = prevMembers.filter((prevMember) => prevMember.name !== member.name);
        updateUserFhirIdsForProvisions(updatedMembers);
        return updatedMembers;
      } else {
        // Add the member to the list
        const updatedMembers = [...prevMembers, member];
        updateUserFhirIdsForProvisions(updatedMembers);
        return updatedMembers;
      }
    });
  };

  const updateUserFhirIdsForProvisions = (updatedMembers: any[]) => {
    // console.log(updatedMembers);
    if (parent) {
      parent.fhirIdsForProvisions = updatedMembers.map((member) => member.fhir_id);

      console.log(parent.fhirIdsForProvisions);
    }
  };

  console.log("practitionersToShare: ", practitionersToShare);

  async function getTeamData() {
    const data = await getCareTeam();
    console.log(data.data.participants);
    const practitionerTeam = data.data.participants.filter(
      (doc: { fhir_id: string }) => !doc.fhir_id.includes("Patient")
    );

    console.log(practitionerTeam);
    return practitionerTeam;
  }

  useEffect(() => {
    const fetchCareTeam = async () => {
      const teamData = await getTeamData();
      setCareTeam(teamData);
    };

    fetchCareTeam();
  }, []);

  const [careTeam, setCareTeam] = useState([]);

  useEffect(() => {
    // Get the initial list of practitioners to Share based on fhirIdsForProvisions
    const initialPractitionersToShare = careTeam.filter((person: Practitioner) =>
      parent?.fhirIdsForProvisions.includes(person.fhir_id)
    );
    setPractitionersToShare(initialPractitionersToShare);
  }, [careTeam, parent?.fhirIdsForProvisions]);

  // const careTeam = getTeamData();
  return (
    <FlowPage pageName={ShareHealthInfo.name}>
      <Header />
      <ProgressBar level={2} description={t("progressBar2")} icon={IconEnum.beaker} />
      <div className="flowPage__padding">
        <Body>
          <Title>{t("caShareTitle")}</Title>
          <Desc>
            <p className="flowPage__p">{t("caShareDesc1")}</p>
            <p className="flowPage__p">
              <Trans>{t("caShareDesc2")}</Trans>
            </p>
            <p className="flowPage__p">
              <Trans>{t("caShareDesc3")}</Trans>
              <ol className="flowPage__orderList">
                <li className="flowPage__orderListItem">{t("caShareDesc3-1")}</li>
                <li className="flowPage__orderListItem">{t("caShareDesc3-2")}</li>
                <li className="flowPage__orderListItem">{t("caShareDesc3-3")}</li>
              </ol>
            </p>
          </Desc>
          <div className="flowPage__careTeamTitle">
            <h3>Care team members: </h3>
            <button>Notify All</button>
          </div>
          <div className="flowPage__careTeam">
            {careTeam.map((person) => (
              <div className="flowPage__careTeamPractitioner">
                <PractitionerShareList
                  person={person}
                  addToPractitionersToShare={addToPractitionersToShare}
                  parent={parent}
                />
              </div>
            ))}
          </div>
          <BtnContainer reverse>
            <Button className="btn--outline">
              <img src={plus} alt="" />
              <p>Add a Care Team Member</p>
            </Button>
            <Button className="btn--outline">Do Not Share</Button>
            <div className="button-navigation">
              <Button className="btn btn--primary" onClick={goBack}>
                {t("back")}
              </Button>
              <Button className="btn btn--primary" onClick={handleNext}>
                {t("next")}
              </Button>
            </div>
          </BtnContainer>
        </Body>
      </div>
    </FlowPage>
  );
};

export default ShareHealthInfo;
