import { useTranslation } from "react-i18next";
import Icon, { IconEnum } from "../components/Icon";
import Modal, { BaseModalProps } from "./Modal";
import Button from "../components/Button";

interface SharedDataProps extends BaseModalProps {
  onClickHandler: () => void;
  isAdult?: boolean;
}

const SharedData = ({ open, setOpen, onClickHandler, isAdult = false }: SharedDataProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(false);
    onClickHandler();
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content>
        <Icon iconName={IconEnum.hands} />
        <Modal.Title>{t("consentRecorded")}</Modal.Title>
        <Modal.Desc>
          <p className="flowPage__p p-centered">{t("consentEdit")}</p>
          {/* <p className="flowPage__p">{ isAdult ? t("dataAdultSharedDesc1") :  t("dataSharedDesc1")}</p> */}
          {/* <p className="flowPage__p">{isAdult ? t("dataAdultSharedDesc2") : t("dataSharedDesc2")}</p> */}
        </Modal.Desc>
      </Modal.Content>
      <Modal.BtnContainer>
        <Button onClick={() => handleClick()} className="btn">
          {t("continue")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};

/*
Fix spacing so this modal is ontop of last
*/

export default SharedData;
