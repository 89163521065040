import { RouteComponentProps } from "react-router-dom";
import { childAssentController } from "../../navigation/routing/Flow/childAssent.logic";
import { childLearnController } from "../../navigation/routing/Flow/childLearn.logic";
import { chooseChildController } from "../../navigation/routing/Flow/chooseChild.logic";
import { chooseParentController } from "../../navigation/routing/Flow/chooseParent.logic";
import { caExplainedController } from "../../navigation/routing/Flow/explained.logic";
import { caNeededController } from "../../navigation/routing/Flow/needed.logic";
import { caDamagesController } from "../../navigation/routing/Flow/damages.logic";
import { caLiabilityController } from "../../navigation/routing/Flow/liability.logic";
import { caTermController } from "../../navigation/routing/Flow/term.logic";
import { caRevokeController } from "../../navigation/routing/Flow/revoke.logic";
import { parentConsentController } from "../../navigation/routing/Flow/parentConsent.logic";
import { parentLearnController } from "../../navigation/routing/Flow/parentLearn.logic";
import { phnParentController } from "../../navigation/routing/Flow/phnParent.logic";
import { phnChildController } from "../../navigation/routing/Flow/phnChild.logic";
import { aboutController } from "../../navigation/routing/Flow/about.logic";
import { redirectController } from "../../navigation/routing/redirectPage.logic";
import { RouteTypes } from "../../navigation/types/RouteTypes";
import {
  CHOOSECHILD,
  CHOOSE_PARENT,
  FLOW_EXPLAINED,
  LIABILITY_RELEASE,
  ONE_YEAR_TERM,
  REVOKE,
  DAMAGES_RELEASE,
  FLOW_NEEDED,
  CHILD_ASSENT_PARENT_VIEW,
  PARENT_CONSENT_PARENT_VIEW,
  PHN_PARENT,
  PARENT_LEARN,
  REDIRECT,
  CHILD_LEARN,
  CHILD_ASSENT_CHILD_VIEW,
  PARENT_CONSENT_CHILD_VIEW,
  PHN_CHILD,
  PHN_ADULT,
  DASHBOARD,
  FLOW_EXPLAINED_ADULT_AND_BABY,
  NOTIFY_CARE_TEAM,
  SHARE_HEALTH_INFO,
  ABOUT,
  CARE_TEAM_ORGANIZATIONS,
} from "../../navigation/utils/RouteConstants";
import { routeScenarioEnum } from "./useRouteController";
import { caExplainedAdultAndBabyController } from "../../navigation/routing/Flow/explainedAdultAndBaby.logic";
import { notifyCareTeamController } from "../../navigation/routing/Flow/notifyCareTeam.logic";
import { shareHealthInfoController } from "../../navigation/routing/Flow/shareHealthInfo.logic";
import { careTeamOrganizationsController } from "../../navigation/routing/Flow/careTeamOrganizations.logic";

// Used in Buttons, submissions, forms, etc
export const routeLogicByPath = (
  path: string,
  routeScenario: routeScenarioEnum,
  routeTypes: (arg: routeScenarioEnum) => RouteTypes,
  history: RouteComponentProps["history"]
) => {
  const types = routeTypes(routeScenario);
  console.log(path, routeScenario);

  if (path === CHOOSECHILD) chooseChildController(types, history);
  else if (path === CHOOSE_PARENT) chooseParentController(types, history);
  else if (path === FLOW_EXPLAINED) caExplainedController(types, history);
  else if (path === FLOW_EXPLAINED_ADULT_AND_BABY)
    caExplainedAdultAndBabyController(types, history);
  else if (path === LIABILITY_RELEASE) caLiabilityController(types, history);
  else if (path === ONE_YEAR_TERM) caTermController(types, history);
  else if (path === REVOKE) caRevokeController(types, history);
  else if (path === DAMAGES_RELEASE) caDamagesController(types, history);
  else if (path === FLOW_NEEDED) caNeededController(types, history);
  else if (path === NOTIFY_CARE_TEAM) notifyCareTeamController(types, history);
  else if (path === CARE_TEAM_ORGANIZATIONS) careTeamOrganizationsController(types, history);
  else if (path === SHARE_HEALTH_INFO) shareHealthInfoController(types, history);
  else if (path === CHILD_ASSENT_PARENT_VIEW) childAssentController(types, history);
  else if (path === CHILD_ASSENT_CHILD_VIEW) childAssentController(types, history);
  else if (path === PARENT_CONSENT_PARENT_VIEW) parentConsentController(types, history);
  else if (path === PARENT_CONSENT_CHILD_VIEW) parentConsentController(types, history);
  else if (path === PHN_PARENT) phnParentController(types, history);
  else if (path === PHN_ADULT) phnParentController(types, history);
  else if (path === PHN_CHILD) phnChildController(types, history);
  else if (path === PARENT_LEARN) parentLearnController(types, history);
  else if (path === CHILD_LEARN) childLearnController(types, history);
  else if (path === REDIRECT) redirectController(types, history);
  else if (path === DASHBOARD) redirectController(types, history);
  else if (path === ABOUT) aboutController(types, history);

  // TODO: Create a RouteController here.
  // * User path === ABOUT_PAGE and create a new redirectController.
};
