import { RouteComponentProps } from "react-router";
import { RouteTypes } from "../../types/RouteTypes";
import { PHN_PARENT, REDIRECT } from "../../utils/RouteConstants";

export const phnChildController = (types: RouteTypes, history: RouteComponentProps["history"]) => {
  if (types.d_child) history.push(PHN_PARENT);
  if (types.i_child) history.push(PHN_PARENT);
  if (types.i_missingAssent) history.push(REDIRECT);
  if (types.i_mailParent) history.push(REDIRECT);
};
