import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import Button from "../components/Button";
import { Dispatch, SetStateAction } from "react";

const OrganizationLearnMoreModal = ({ 
  open, 
  setOpen,
  title, 
  content,
}: {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  title: string,
  content: string
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content scrollbox>
        <Modal.Title>{title}</Modal.Title>
        <Modal.Desc textLeft>
          <p className="flowPage__p">{content}</p>
        </Modal.Desc>
      </Modal.Content>
      <Modal.BtnContainer scrollbox>
        <Button onClick={() => handleClick()} className="btn">
          {t("gotIt")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};


export default OrganizationLearnMoreModal;
