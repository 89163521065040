import { useTranslation } from "react-i18next";
import Modal, { BaseModalProps } from "./Modal";
import Button from "../components/Button";

const Ferpa = ({ open, setOpen }: BaseModalProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Content scrollbox>
        <Modal.Title>{t("ferpaTitle")}</Modal.Title>
        <Modal.Desc textLeft>
          <p className="flowPage__p">{t("ferpaDesc1")}</p>
        </Modal.Desc>
      </Modal.Content>
      <Modal.BtnContainer scrollbox>
        <Button onClick={() => handleClick()} className="btn">
          {t("gotIt")}
        </Button>
      </Modal.BtnContainer>
    </Modal>
  );
};

/*
Fix spacing so this modal is ontop of last
*/

export default Ferpa;
