import axios from "axios";
import { BASE_URL } from "../App";
import {  PhnAgreementType, UserRelated, UserTypes } from "../context/UserContext/UserTypes";
import { getFirstParentIndex } from "../utils/firstParentIndex";


export const getCareTeam = async () => {
  return axios({
    url: `${BASE_URL}/user/care-team`,
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    withCredentials: true,
  });
};


export const updatePhnAgreement = async ( token: string, phn_agreement: PhnAgreementType) => {
  return axios({
    url: `${BASE_URL}/user/phn`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Md4r-Token": `${token}`,
    },
    data: phn_agreement,
    withCredentials: true,
  });
};


export const updateAgreedBefore = async (
  hasAgreedBefore: boolean, 
  token: string, 
  userDetails: UserTypes
) => {
  let body: UserTypes = { ...userDetails, hasAgreedBefore };

  return axios({
    url: `${BASE_URL}/user/consent`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Md4r-Token": `${token}`,
    },
    data: body,
    withCredentials: true,
  });
};


export const updateAgreedBeforeForMainUserChild = async (
  hasAgreedBefore: boolean, 
  token: string, 
  userDetails: UserTypes
) => {
  let body: UserTypes;
  const firstParentIndex = getFirstParentIndex(userDetails);

 const isThereRelatedParent = firstParentIndex >= 0;
 
  if (isThereRelatedParent) {
    userDetails.related[firstParentIndex].hasAgreedBefore = hasAgreedBefore;
  }

  body = userDetails;

  await axios({
    url: `${BASE_URL}/user/consent`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Md4r-Token": `${token}`,
    },
    data: body,
    withCredentials: true,
  });
};


export const updateAgreement = async (
  token: string, 
  userDetails: UserTypes, 
  children: Array<UserRelated>
) => {
  let body: UserTypes;

  body = { ...userDetails, related: reduceUserDetailsRelated(userDetails, children) };

  if (userDetails.hasAgreedBefore === true) {
    return updateConsent(body, token);
  }
  return createConsent(body, token);
};

const reduceUserDetailsRelated = (
  userDetails: UserTypes,
  persons: Array<UserRelated>
) => {
  let identifiers: Array<string> = persons.map(person => person.identifier)

  const filteredUserDetailsRelatedEntries = userDetails.related.filter((child) => 
    !identifiers.includes(child.identifier));

  return [...persons, ...filteredUserDetailsRelatedEntries]
}


export const updateAgreementUserChild = async (
  token: string, 
  userDetails: UserTypes
) => {
  let body: UserTypes;

  body = { ...userDetails };

  return createConsent(body, token);
};


const createConsent = async (data: UserTypes, token: string) => {
  if (data.name === "") {
    throw new Error("Must re-hydrate app with state");
  }
  return axios({
    url: `${BASE_URL}/user/consent`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Md4r-Token": `${token}`,
    },
    data,
    withCredentials: true,
  });
};

const updateConsent = async (data: UserTypes, token: string) => {
  if (data.name === "") {
    throw new Error("Must re-hydrate app with state");
  }
  return axios({
    url: `${BASE_URL}/user/consent`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Md4r-Token": `${token}`,
    },
    data,
    withCredentials: true,
  });
};


export const stopParticipatingInResearch = async (token: string, userDetails: UserTypes, children: Array<UserRelated>) => {
  let body: UserTypes;

  body = { ...userDetails, related: children };
  return stopParticipating(body, token);
};

const stopParticipating = async (data: {}, token: string) => {
  return axios({
    url: `${BASE_URL}/user/stop-participating`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Md4r-Token": `${token}`,
    },
    data,
    withCredentials: true,
  });
};


export const logout = async (token: string) => {
  return axios({
    url: `${BASE_URL}/user/logout`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Md4r-Token": `${token}`,
    },
  });
}


/*
UTILITY HELPER FUNCTIONS
*/

export const keepUnusedChildren = (userDetails: UserTypes, children: Array<UserRelated>) => {
  const holder = userDetails.related.filter((child) => {
    return !children.includes(child);
  });

  console.log([...holder, ...children], "consent & assent");

  return [...holder, ...children];
};
