import React from "react";
import {
  // Trans,
  useTranslation,
} from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import { IconEnum } from "../../../components/Icon";
import { useRoute } from "../../../context/RouteContext/RouteContext";
import useRouteControl, {
  routeScenarioEnum,
} from "../../../hooks/RouteController/useRouteController";

import FlowPage, {
  Header,
  Body,
  Title,
  Desc,
  BtnContainer,
  ProgressBar,
  // CaptionContainer,
} from "../FlowPage.wrapper";

const OneYearTerm = () => {
  const { t } = useTranslation();
  const { routeScenario } = useRoute();
  const history = useHistory();

  const [handleRoute] = useRouteControl(history);

  const handleNext = () => {
    handleRoute(routeScenario as routeScenarioEnum);
  };
  const handleBackBtn = () => {
    history.goBack();
  };

  return (
    <FlowPage pageName={OneYearTerm.name}>
      <Header />
      <ProgressBar level={6} description={t("progressBar6")} icon={IconEnum.beaker} />
      <div className="flowPage__padding">
        <Body>
          <Title>{t("termTitle")}</Title>
          <Desc>
            <p className="flowPage__p">{t("termContent")}</p>
          </Desc>
          <BtnContainer reverse>
            <Button onClick={handleBackBtn} className="btn btn--outline btn--red">
              {t("notAgree")}
            </Button>
            <Button className="btn btn--full-width" onClick={handleNext}>
              {t("agree")}
            </Button>
          </BtnContainer>
        </Body>
      </div>
    </FlowPage>
  );
};

export default OneYearTerm;
