import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useRoute } from "../../context/RouteContext/RouteContext";
import { UserRelated } from "../../context/UserContext/UserTypes";
import useRouteControl, { routeScenarioEnum } from "../../hooks/RouteController/useRouteController";
import { AgreementSection } from "../../navigation/pages/LandingPage.wrapper";
import plus from "../../images/icon_closegrey.png";
import { CaptionContainer } from "../../navigation/pages/FlowPage.wrapper";

interface MissingAgreementProps {
  children?: Array<UserRelated>;
  areAllChildrenNotParticipating?: boolean;
}

const MissingAgreement = ({ children, areAllChildrenNotParticipating }: MissingAgreementProps) => {
  const [t] = useTranslation("common");
  const { routeScenario } = useRoute();
  const history = useHistory();

  const [handleRoute] = useRouteControl(history);

  const onClickHandler = () => {
    handleRoute(routeScenario as routeScenarioEnum);
  };

  // const renderTitle = () => {
  //   if (areAllChildrenNotParticipating) {
  //     return t("homeMissingAgreementTitle-Main");
  //   }
  //   if (children?.length) {
  //     const word = children.length <= 1 ? "child" : "children";
  //     return t("homeMissingAgreementTitle", { child: word });
  //   } else {
  //     return t("homeMissingAgreementTitle-Main");
  //   }
  // };

  return (
    <AgreementSection>
      <h3>Active Consent Forms</h3>
      <p className="homeAuth__p">{t("homeMissingAgreementDesc1")}</p>
      <div className="landingPage__consent">
        {/* <div className="landingPage__consentForm"> */}
        {/* <ConsentFormButton status="fullAgreement" onClickHandler={onClickHandler} /> */}
        <CaptionContainer>
          <p className="landingPage__caption">{t("noActiveConsent")}</p>
        </CaptionContainer>
        {/* </div> */}
      </div>
      <h3>Pending Consent Forms</h3>
      <p className="homeAuth__p">{t("homeMissingAgreementDesc1")}</p>
      <div className="landingPage__consent consent-pending">
        <div className="landingPage__consentForm ">
          {/* <ConsentFormButton status="fullAgreement" onClickHandler={onClickHandler} /> */}
          <li className={"landingPage__consentItem"}>
            <button onClick={onClickHandler}>
              <img src={plus} alt="" />
              <p>ADD</p>
            </button>
            <div className="landingPage__p">
              <h4>Baker Act Parental Consent</h4>
              <p>
                Parent Consent to Notify CARE Team Members and Share CARE Team Member Information
              </p>
            </div>
          </li>
        </div>
      </div>
      <h3 className="second-title">Past Consent Forms</h3>
      <p className="homeAuth__p">{t("homeMissingAgreementDesc1")}</p>
      <div className="landingPage__consent">
        {/* <div className="landingPage__consentForm"> */}
        {/* <ConsentFormButton status="fullAgreement" onClickHandler={onClickHandler} /> */}
        <CaptionContainer>
          <p className="landingPage__caption">{t("noPastConsent")}</p>
        </CaptionContainer>
        {/* </div> */}
      </div>
      {/* <Button onClick={onClickHandler} className="btn ">
        {t("homeMissingAgreementBtn")}
      </Button> */}
    </AgreementSection>
  );
};

export default MissingAgreement;
