import { useContext, createContext, useMemo, useState } from "react";
import { appCtxDefaultValue } from "../UserContext/UserContext";
import { UserRelated, UserTypes } from "../UserContext/UserTypes";

export type ChildOrParent = UserRelated | UserTypes;

const utilCtxDefaultValue = {
  missingAssent: appCtxDefaultValue.children,
  setMissingAssent: (missingAssent: React.SetStateAction<UserRelated[]>) => {
    // This is intentional, using fn body to build type
  },
  missingConsent: appCtxDefaultValue.children,
  setMissingConsent: (missingConsent: React.SetStateAction<UserRelated[]>) => {
    // This is intentional, using fn body to build type
  },
  fullAgreement: appCtxDefaultValue.children,
  setFullAgreement: (fullAgreement: React.SetStateAction<UserRelated[]>) => {
    // This is intentional, using fn body to build type
  },
  missingAgreement: appCtxDefaultValue.children,
  setMissingAgreement: (missingAgreement: React.SetStateAction<UserRelated[]>) => {
    // This is intentional, using fn body to build type
  },
  expiredAgreement: [...appCtxDefaultValue.children, appCtxDefaultValue.userDetails],
  setExpiredAgreement: (expiredAgreement: React.SetStateAction<ChildOrParent[]>) => {
    // This is intentional, using fn body to build type
  },
  pageName: {
    current: ""
  },
  setPageName: (pageName: any) => {
    // This is intentional, using fn body to build type
  },
};

const UtilContext = createContext(utilCtxDefaultValue);

function useUtil() {
  return useContext(UtilContext);
}

interface UtilProviderProps {
  children: React.ReactNode;
}

function UtilProvider(props: UtilProviderProps) {
  const [missingAssent, setMissingAssent] = useState<Array<UserRelated>>([]);
  const [fullAgreement, setFullAgreement] = useState<Array<UserRelated>>([]);
  const [missingAgreement, setMissingAgreement] = useState<Array<UserRelated>>([]);
  const [missingConsent, setMissingConsent] = useState<Array<UserRelated>>([]);
  const [expiredAgreement, setExpiredAgreement] = useState<Array<ChildOrParent>>([]);
  const [pageName, setPageName] = useState({current: ""});

  // Memoized state management, used to reduce unnecessary state updates
  const providerValue = useMemo(
    () => ({
      missingAssent,
      setMissingAssent,
      fullAgreement,
      setFullAgreement,
      missingAgreement,
      setMissingAgreement,
      expiredAgreement,
      setExpiredAgreement,
      missingConsent,
      setMissingConsent,
      pageName,
      setPageName
    }),
    [
      missingAssent,
      setMissingAssent,
      fullAgreement,
      setFullAgreement,
      missingAgreement,
      setMissingAgreement,
      expiredAgreement,
      setExpiredAgreement,
      missingConsent,
      setMissingConsent,
      pageName,
      setPageName
    ]
  );

  return <UtilContext.Provider value={providerValue} {...props} />;
}
export { UtilProvider, useUtil };
